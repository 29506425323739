import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { Loader } from 'semantic-ui-react'
import { isEmpty, toInteger } from 'lodash'

import { fetchQaAuditEventsByCallId } from '@/reducers/scorecards/scores.actions'
import {
  deleteScorecardNote,
  updateScorecardNote,
  updateScoreCriteria,
} from '@/reducers/callSearch/callSearch.actions'
import NoData from '@/components/NoData'

import { IconInfoCircle } from '@tabler/icons-react'
import { Banner } from '@/components/banners/Banner'
import pluralize from 'pluralize'
import { parseCallExplorerParams, updateCallExplorerParams } from './helpers'
import { QANote } from './QANote'
import { QAScore } from './QAScore'
import { QAScoreEditMode } from './QAScoreEditMode'

import './QAScore.scss'

export const CallQAScores = ({ callId, organizationId, metadata = {}, setOpenTab }) => {
  const [scorecardIdToEdit, setScorecardIdToEdit] = useState(null)
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const {
    callExplorer: { qaScores },
    loading: { qaScores: qaScoresLoading },
  } = useSelector((state) => state.callSearch)
  const { user_id: userId } = useSelector((state) => state.currentUser)
  const skippedScorecards =
    !isEmpty(metadata?.qa_eligibility) &&
    metadata?.qa_eligibility.map((skip) => {
      return { scorecardName: skip.config_name, reason: skip.reason }
    })

  useEffect(() => {
    const { scorecardId } = parseCallExplorerParams(location)

    if (scorecardId) {
      setScorecardIdToEdit(Number(scorecardId))
    }
  }, [])

  const handleEditScore = (score) => {
    updateCallExplorerParams(location, history, { scorecardId: score.id })
    setScorecardIdToEdit(score.id)
  }

  const handleCancelEdit = () => {
    setScorecardIdToEdit(null)
  }

  const handleSaveScore = (formValues, initialValues) => {
    const updatedCriteria = Object.keys(formValues)
      .filter((criteriaUuid) => {
        // Prevent double-processing of notes
        if (criteriaUuid.includes('-note')) {
          return false
        }
        const scoreChanged = formValues[criteriaUuid] !== initialValues[criteriaUuid]
        const noteKey = `${criteriaUuid}-note`
        const noteChanged =
          initialValues[noteKey] !== undefined
            ? formValues[noteKey] !== initialValues[noteKey]
            : false
        return scoreChanged || noteChanged
      })
      .map((criteriaUuid) => {
        const score = formValues[criteriaUuid]
        const noteKey = `${criteriaUuid}-note`
        const note = formValues[noteKey]
        const skipped = score === '-1'

        return {
          scorecard_criteria_score_uuid: criteriaUuid,
          score: toInteger(score),
          skipped,
          note,
        }
      })

    dispatch(updateScoreCriteria(scorecardIdToEdit, updatedCriteria))
    dispatch(fetchQaAuditEventsByCallId(callId, organizationId))
    setScorecardIdToEdit(null)
  }

  const handleSaveNote = (scorecardId, note_text) => {
    dispatch(updateScorecardNote(scorecardId, note_text))
    dispatch(fetchQaAuditEventsByCallId(callId, organizationId))
    setScorecardIdToEdit(null)
  }

  const handleDeleteNote = (scorecardId) => {
    dispatch(deleteScorecardNote(scorecardId))
    dispatch(fetchQaAuditEventsByCallId(callId, organizationId))
    setScorecardIdToEdit(null)
  }

  const buildSkippedScorecardsTable = () => (
    <Banner
      data-testid="copilot-scorecards-not-eligible"
      informative
      shrunk
      icon={<IconInfoCircle />}
    >
      <p>
        This call was not scored for{' '}
        {`${pluralize('scorecard', Number(skippedScorecards.length), true)}`}. Our system uses
        specific criteria to determine eligibility, and this call did not meet those requirements.
        For more details,{' '}
        <a data-testid="banner-link-to-details" onClick={() => setOpenTab('details')}>
          click here
        </a>
        .
      </p>
    </Banner>
  )

  if (qaScoresLoading) {
    return (
      <div className="empty-table">
        <Loader inline active />
      </div>
    )
  }

  if (!isEmpty(qaScores)) {
    if (scorecardIdToEdit) {
      const score = qaScores.find((score) => score.id === scorecardIdToEdit)

      if (score) {
        return (
          <QAScoreEditMode
            score={score}
            handleCancelEdit={handleCancelEdit}
            handleSaveScore={handleSaveScore}
            callId={callId}
            userId={userId}
          />
        )
      }
    }

    return (
      <div className="qa-scores-container">
        {!isEmpty(skippedScorecards) && buildSkippedScorecardsTable()}
        <div className="qa-scores">
          {qaScores.map((scorecard) => (
            <div className="qa-score" key={scorecard.uuid}>
              <QAScore
                key={scorecard.uuid}
                scorecard={scorecard}
                handleEditScore={handleEditScore}
              />
              <QANote
                handleSaveNote={handleSaveNote}
                handleDeleteNote={handleDeleteNote}
                scorecard={scorecard}
              />
            </div>
          ))}
        </div>
      </div>
    )
  }

  return (
    <>
      {!isEmpty(skippedScorecards) && buildSkippedScorecardsTable()}
      <div className="empty-table">
        <NoData text="No QA scores associated with this call" />
      </div>
    </>
  )
}
