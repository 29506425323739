import React from 'react'
import { useSelector } from 'react-redux'
import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import { Pill } from '@/components/pills/Pill'
import { Icon, Segment } from 'semantic-ui-react'
import { formatTime } from '@/utils/helpers'
import '../organizations.scss'

const mapColumNames = {
  cloud_access: 'Balto Cloud Access',
  code: 'VoIP User ID',
  config_cid: 'Playbook',
  deleted: 'Status',
  edit_config: 'Playbook Management',
  edit_leaderboard: 'Leaderboard Management',
  edit_qa: 'QA Access',
  edit_users: 'User Management',
  first_name: 'First Name',
  game_admin: 'Gamification Admin',
  hierarchy_manager: 'Hierarchy Manager',
  last_name: 'Last Name',
  midcall_playbook_switching_enabled: 'Midcall Playbook Switching',
  prompt_editor: 'Prompt Editor',
  realtime_coaching_access: 'Real-Time Coaching Access',
  screen_capture_enabled: 'Screen Capture',
  uncategorizedTags: 'Uncategorized Tags',
  username: 'Email',
}

const isBool = (value) => ['true', 'false'].includes(value?.toString())

const getColumnName = (column) =>
  mapColumNames[column] ??
  column
    .split([' ', '_'])
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')

const ChangeRow = ({ column, children }) => (
  <div className="flex-align-center" style={{ margin: '5px 0' }}>
    <div className="audit-column-title">{getColumnName(column)}</div>
    <div className="audit-column-details">
      <div className="flex-align-center" style={{ justifySelf: 'end' }}>
        {children[0]}
      </div>
      <div className="flex-align-center" style={{ padding: '0 8px' }}>
        <Icon name="arrow right" size="small" />
      </div>
      <div className="flex-align-center">{children[1]}</div>
    </div>
  </div>
)

export const ValueChange = ({ changes }) => {
  const playbooks = useSelector((state) => state.configs)

  if (!changes) {
    return null
  }
  const keys = Object.keys(changes)

  const result = []
  keys.forEach((key) => {
    const change = changes[key]
    let type = change?.type
    if (isBool(change?.new) || isBool(change?.old)) {
      type = 'boolean'
    } else if (key === 'config_cid' && change?.type === 'value') {
      type = 'playbook'
    }

    switch (type) {
      case 'new_user':
        result.push(
          <div key={key}>{`User added ${key === 'new_user_single' ? '(manually)' : '(bulk)'}`}</div>
        )
        break
      case 'boolean':
        result.push(
          <ChangeRow column={key} key={key}>
            {change?.old ? 'On' : 'Off'}
            {change?.new ? 'On' : 'Off'}
          </ChangeRow>
        )
        break
      case 'categorizedTags':
        result.push(
          <ChangeRow column={key} key={key}>
            {change?.old?.value ? (
              <Pill small emphasized key={change.old.value}>
                {change?.old?.label}
              </Pill>
            ) : (
              'None'
            )}
            {change?.new?.value ? (
              <Pill small emphasized key={change?.new?.value}>
                {change?.new?.label}
              </Pill>
            ) : (
              'None'
            )}
          </ChangeRow>
        )
        break
      case 'uncategorizedTags':
        result.push(
          <ChangeRow column={key} key={key}>
            {change?.old?.length > 0
              ? change.old.map((item) => (
                  <Pill small emphasized key={item.value} style={{ margin: '3px' }}>
                    {item.label ?? 'None'}
                  </Pill>
                ))
              : 'None'}
            {change?.new?.length > 0
              ? change.new.map((item) => (
                  <Pill small emphasized key={item.value} style={{ margin: '3px' }}>
                    {item.label ?? 'None'}
                  </Pill>
                ))
              : 'None'}
          </ChangeRow>
        )
        break
      case 'playbook':
        result.push(
          <ChangeRow column={key} key={key}>
            <div>{(playbooks || []).find((p) => p.cid === change?.old)?.name}</div>
            <div>{(playbooks || []).find((p) => p.cid === change?.new)?.name}</div>
          </ChangeRow>
        )
        break
      case 'value':
        result.push(
          <ChangeRow column={key} key={key}>
            {change?.old ?? 'None'}
            {change?.new ?? 'None'}
          </ChangeRow>
        )
        break
      case 'reset_password_email':
        result.push('Password Reset Email Sent')
        break
      case 'reset_password':
        result.push('Add/Reset Password')
        break
      default:
        break
    }
  })
  return result
}

const columns = [
  {
    accessor: 'change',
    label: 'What changed',
    isSearchable: false,
    format: (_, row) => <ValueChange changes={row.event_payload} />,
  },
  {
    accessor: 'author',
    label: 'Who changed it',
    isSearchable: true,
  },
  {
    accessor: 'created_at',
    label: 'When it happened',
    isSearchable: false,
    format: (created_at) => formatTime(created_at),
  },
]

const UserAuditTable = () => {
  const { userId, fullName } = useSelector((state) => state.organizationUsers.userAuditSelected)
  const { loading, userAudit } = useSelector((state) => state.organizationUsers)

  return (
    <>
      <h3>User Change History{fullName && ` - ${fullName}`}</h3>
      <Segment className="not-padded" data-testid="user-audit-table">
        <AdvancedTable
          columns={columns}
          loading={loading?.userAudit}
          noDataText={
            userId
              ? 'User has no Change History data'
              : 'Please select one of the users to see the Change History'
          }
          rows={userAudit}
          sortEnabled={false}
        />
      </Segment>
    </>
  )
}

export default UserAuditTable
