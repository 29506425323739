import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Modal, Button, Input, TextArea } from 'semantic-ui-react'
import { updatePromptTestDescription } from '@/reducers/prompts/prompts.actions'

const DescriptionField = ({ promptTest, promptId }) => {
  const [isModalOpen, setModalOpen] = useState(false)
  const [description, setDescription] = useState(promptTest.description || '')
  const dispatch = useDispatch()

  const handleModalOpen = () => setModalOpen(true)
  const handleModalClose = () => setModalOpen(false)

  const handleUpdateDescription = async () => {
    try {
      dispatch(updatePromptTestDescription(promptId, promptTest.id, description))
      handleModalClose()
    } catch (error) {
      console.error('Failed to update descriptison:', error)
    }
  }

  return (
    <>
      {/* Field with popup functionality */}
      <Input
        value={description}
        placeholder={promptTest.description ?? 'Add test description'}
        onClick={handleModalOpen}
        readOnly
        className="test-description-field"
      />

      {/* Modal for editing the description */}
      <Modal open={isModalOpen} onClose={handleModalClose} size="small">
        <Modal.Header>Edit Test Description</Modal.Header>
        <Modal.Content>
          <TextArea
            value={description}
            placeholder={promptTest.description ?? 'Add test description'}
            onChange={(e) => setDescription(e.target.value)}
            style={{ width: '100%', minHeight: '100px' }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={handleModalClose} color="grey">
            Cancel
          </Button>
          <Button onClick={handleUpdateDescription} color="blue">
            Update Description
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default DescriptionField
