import * as Yup from 'yup'
import { toNumber } from 'lodash'

import { customStaticRanges } from '@/components/helpers'

export const buildSchema = (
  data,
  loading,
  filterFormOptions = [],
  handlePlaybookChangeSideEffect
) => {
  const nameSchema = {
    name: 'name',
    type: 'text',
    label: 'Filter Name',
    required: true,
    placeholder: 'Name',
  }

  const defaultSchema = {
    name: 'isDefault',
    type: 'checkbox',
    label: 'Set as default filter',
  }

  const staticSchema = {
    type: 'header',
    label: 'Filter Options',
    name: 'filterPropertiesHeader',
    isStatic: true,
    as: 'h4',
  }

  const dateRangeSchema = {
    name: 'dateRange',
    label: 'Date Range',
    placeholder: 'Select Date Range',
    options: customStaticRanges.map((staticRange) => {
      return {
        value: staticRange.label.toLowerCase(),
        label: staticRange.label,
      }
    }),
    type: 'select',
  }

  const agentSchema = {
    name: 'agents',
    label: 'Agents',
    type: 'multiSelect',
    placeholder: 'Search Agents',
    options: data.agents,
    condenseOptions: false,
    loading: loading.agents,
    showFullOptions: true,
  }

  const tagSchema = {
    name: 'tags',
    label: 'Tags',
    options: data.tags,
    condenseOptions: false,
    placeholder: 'Select Tags',
    type: 'multiSelect',
    loading: loading.tags,
    showFullOptions: true,
  }

  const playbookSchema = {
    name: 'playbooks',
    label: 'Playbooks',
    options: data.playbooks,
    condenseOptions: false,
    placeholder: 'Select Playbook(s)',
    type: 'multiSelect',
    loading: loading.playbooks,
    customOnChange: (option) => {
      handlePlaybookChangeSideEffect(option)
    },
  }

  const minDurationSchema = {
    name: 'callDuration',
    label: 'Min. Call Duration',
    type: 'text',
    inputType: 'number',
    customValidators: [
      Yup.number().when('maxCallDuration', {
        is: (val) => toNumber(val) > 0,
        then: Yup.number()
          .label('Min. Call Duration')
          .min(0)
          .lessThan(Yup.ref('maxCallDuration'), 'Min must be less than max'),
        otherwise: Yup.number().label('Min. Call Duration').min(0),
      }),
    ],
    cyclicDependency: true,
  }

  const maxDurationSchema = {
    name: 'maxCallDuration',
    label: 'Max. Call Duration',
    type: 'text',
    inputType: 'number',
    customValidators: [
      Yup.number().when('callDuration', {
        is: (val) => toNumber(val) > 0,
        then: Yup.number()
          .label('Max. Call Duration')
          .positive()
          .moreThan(Yup.ref('callDuration'), 'Max must be greater than min'),
        otherwise: Yup.number().label('Max. Call Duration').positive(),
      }),
    ],
    cyclicDependency: true,
  }

  const includeManagersSchema = {
    name: 'includeManagers',
    label: 'Include Managers',
    type: 'checkbox',
  }

  const callsWithoutAudioSchema = {
    name: 'includeCallsWithoutAudio',
    label: 'Include Calls Without Audio',
    type: 'checkbox',
  }

  const winsSchema = {
    name: 'isWin',
    label: 'Wins',
    type: 'select',
    options: [
      { value: 'true', label: 'Only wins' },
      { value: 'false', label: 'Only non-wins' },
    ],
  }

  const keywordStaticSchema = {
    type: 'header',
    label: 'Keyword Options',
    name: 'keywordHeader',
    isStatic: true,
    as: 'h4',
  }

  const keywordsSchema = {
    name: 'keywords',
    label: 'Keywords',
    type: 'text',
  }

  const keywordLogicOption = {
    name: 'keywordOptions.logic',
    label: 'Keyword Logic',
    type: 'radioGroup',
    options: [
      { label: 'And', value: 'and' },
      { label: 'Or', value: 'or' },
    ],
  }

  const keywordSideOption = {
    name: 'keywordOptions.side',
    label: 'Keyword Side',
    type: 'radioGroup',
    options: [
      { label: 'Me', value: 'me' },
      { label: 'Them', value: 'them' },
      { label: 'Both', value: 'both' },
    ],
  }
  const playbookStaticSchema = {
    type: 'header',
    label: 'Playbook Options',
    name: 'playbookPropertiesHeader',
    isStatic: true,
    as: 'h4',
  }

  // Reporting
  const deckCategoriesSchema = {
    name: 'deckCategories',
    label: 'Dynamic Prompt Categories',
    options: data.deckCategories,
    type: 'multiSelect',
    loading: loading.deckCategories,
    condenseOptions: false,
  }

  const postcallCategoriesSchema = {
    name: 'postcallCategories',
    label: 'Post Call Categories',
    options: data.postcallCategories,
    type: 'multiSelect',
    loading: loading.postcallCategories,
    condenseOptions: false,
  }

  // Call Explorer
  const checklistSchema = {
    name: 'checklist',
    label: 'Checklist',
    type: 'nestedSelect',
  }

  const deckSchema = {
    name: 'deck',
    label: 'Dynamic Prompt',
    type: 'nestedSelect',
  }

  const notificationsSchema = {
    name: 'notifications',
    label: 'Notifications',
    type: 'nestedSelect',
  }

  const postcallSchema = {
    name: 'postcall',
    label: 'Post Call',
    type: 'nestedSelect',
  }

  const allOptions = [
    nameSchema,
    defaultSchema,
    staticSchema,
    dateRangeSchema,
    agentSchema,
    tagSchema,
    playbookSchema,
    minDurationSchema,
    maxDurationSchema,
    winsSchema,
    callsWithoutAudioSchema,
    includeManagersSchema,
    keywordStaticSchema,
    keywordsSchema,
    keywordLogicOption,
    keywordSideOption,
    playbookStaticSchema,
    deckCategoriesSchema,
    postcallCategoriesSchema,
    checklistSchema,
    deckSchema,
    notificationsSchema,
    postcallSchema,
  ]

  return allOptions.filter(({ name }) => filterFormOptions.includes(name))
}
