// Action Types
export const SET_USERS = 'organizations/setUsers'
export const SET_LOADING = 'organizations/setLoading'
export const SET_USER_PERMISSION_COLUMNS = 'organizations/setUserPermissions'
export const SELECT_USER_AUDIT = 'organizations.selectUserAudit'
export const SET_USER_AUDIT = 'organizations/setUserAudit'

// Action Creators
export const setUsers = (payload) => ({ type: SET_USERS, payload })
export const setLoading = (payload) => ({ type: SET_LOADING, payload })
export const setUserPermissionColumns = (payload) => ({
  type: SET_USER_PERMISSION_COLUMNS,
  payload,
})
export const selectUserAudit = (payload) => ({ type: SELECT_USER_AUDIT, payload })
export const setUserAudit = (payload) => ({ type: SET_USER_AUDIT, payload })

// State
export const initialState = {
  loading: {
    userAudit: false,
    userPermissions: false,
    userTable: false,
  },
  userAudit: [],
  userAuditSelected: {},
  userPermissionColumns: [],
  users: [],
}

// Reducer
export default function organizationUsersReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USERS:
      return { ...state, users: action.payload }
    case SET_LOADING:
      return { ...state, loading: { ...state.loading, ...action.payload } }
    case SET_USER_PERMISSION_COLUMNS:
      return { ...state, userPermissionColumns: action.payload }
    case SELECT_USER_AUDIT:
      return { ...state, userAuditSelected: action.payload }
    case SET_USER_AUDIT:
      return { ...state, userAudit: action.payload }
    default:
      return state
  }
}
