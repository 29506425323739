import React, { useState } from 'react'
import { Checkbox } from 'semantic-ui-react'

import Select from '@/components/forms/Select'

export const OrganizationToggleGroup = ({
  production,
  leaderboardVisible,
  isBaltoAdmin,
  isBaltoOrg,
  qaEnabled,
  qaCopilotEnabled,
  dataAccessKey,
  remoteDesktopKey,
  handleModalChange,
  rtnEnabled,
  showSummarizationNotes,
  audioExpiryDays,
}) => {
  const [showDataAccessKey, setShowDataAccessKey] = useState(false)
  const [showRemoteDesktopKey, setShowRemoteDesktopKey] = useState(false)
  const autoStartToken = {
    remote_desktop_key: remoteDesktopKey,
  }
  const generateDataAccessKey = {
    data_access_key: dataAccessKey,
  }
  const handleRemoteDesktopClick = () => {
    if (!remoteDesktopKey) {
      handleModalChange(autoStartToken, 'Generate Auto-Start Token')
      setShowRemoteDesktopKey(!showRemoteDesktopKey)
    }
    setShowRemoteDesktopKey(!showRemoteDesktopKey)
  }

  const handleDataAccessKeyClick = () => {
    if (!dataAccessKey) {
      handleModalChange(generateDataAccessKey, 'Generate Data Access Key')
      setShowDataAccessKey(!showDataAccessKey)
    }
    setShowDataAccessKey(!showDataAccessKey)
  }
  const productionChange = {
    production: !production,
  }
  const leaderboardVisibilityChange = {
    desktop_leaderboard_visibility: !leaderboardVisible,
  }
  const qaEnabledChange = {
    qa_enabled: !qaEnabled,
  }
  const qaCopilotEnabledChange = {
    qa_copilot_enabled: !qaCopilotEnabled,
  }
  const rtnEnabledChange = {
    call_summarization_enabled: !rtnEnabled,
  }
  const showSummarizationNotesChange = {
    show_summarization_notes: !showSummarizationNotes,
  }

  const audioExpiryOptions = [
    { label: '30 days', value: 30 },
    { label: '60 days', value: 60 },
    { label: '90 days', value: 90 },
    { label: '120 days', value: 120 },
    { label: '150 days', value: 150 },
    { label: '180 days', value: 180 },
    { label: '1 year', value: 365 },
    { label: '2 years', value: 730 },
    { label: '3 years', value: 1095 },
    { label: '4 years', value: 1460 },
    { label: '5 years', value: 1825 },
    { label: '6 years', value: 2190 },
    { label: '7 years', value: 2555 },
    { label: '8 years', value: 2920 },
    { label: '9 years', value: 3285 },
    { label: '10 years', value: 3650 },
  ]

  return (
    <>
      <div className="ui segment">
        <h3>Production</h3>
        <Checkbox
          data-testid="checkbox-production"
          toggle
          checked={production || false}
          onChange={() => handleModalChange(productionChange, 'Change Production Mode')}
        />
      </div>
      <div className="ui segment">
        <h3>Leaderboard Visibility</h3>
        <Checkbox
          data-testid="checkbox-leaderboard"
          toggle
          checked={leaderboardVisible}
          onChange={() =>
            handleModalChange(leaderboardVisibilityChange, 'Change Leaderboard Visibility')
          }
        />
      </div>
      {isBaltoAdmin && !isBaltoOrg && (
        <>
          <div className="ui segment">
            <h3>QA Enabled</h3>
            <Checkbox
              data-testid="checkbox-qa"
              toggle
              checked={qaEnabled}
              onChange={() => handleModalChange(qaEnabledChange, 'Change QA Enabled')}
            />
          </div>
          <div className="ui segment">
            <h3>QA Copilot Enabled</h3>
            <Checkbox
              data-testid="checkbox-qa-copilot"
              toggle
              checked={qaCopilotEnabled}
              onChange={() =>
                handleModalChange(qaCopilotEnabledChange, 'Change QA Copilot Enabled')
              }
            />
          </div>
          <div className="ui segment">
            <h3>RTN Enabled</h3>
            <Checkbox
              data-testid="checkbox-rtn"
              toggle
              checked={rtnEnabled}
              onChange={() => handleModalChange(rtnEnabledChange, 'Change RTN Enabled')}
            />
          </div>
          <div className="ui segment">
            <h3>Show Agents RTN Summaries</h3>
            <Checkbox
              data-testid="checkbox-summarization"
              toggle
              checked={showSummarizationNotes}
              onChange={() =>
                handleModalChange(
                  showSummarizationNotesChange,
                  'Change - Show Agents Their Own RTN Summaries'
                )
              }
            />
          </div>
        </>
      )}
      {isBaltoAdmin && !isBaltoOrg && (
        <>
          <div className="ui segment">
            <h3 data-testid="remote-desktop-key-header">Auto-Start Token</h3>
            {showRemoteDesktopKey && remoteDesktopKey != null && (
              <p data-testid="remote-desktop-key-value">{remoteDesktopKey}</p>
            )}
            <button
              data-testid="remote-desktop-button"
              type="button"
              onClick={handleRemoteDesktopClick}
              className="ui primary button"
            >
              {!remoteDesktopKey ? 'Generate' : showRemoteDesktopKey ? 'Hide' : 'Show'}
            </button>
          </div>

          <div className="ui segment">
            <h3 data-testid="data-access-key-header">Data Access Key</h3>
            {showDataAccessKey && dataAccessKey != null && (
              <p data-testid="data-access-key-value">{dataAccessKey}</p>
            )}
            <button
              data-testid="data-access-key-button"
              type="button"
              onClick={handleDataAccessKeyClick}
              className="ui primary button"
            >
              {!dataAccessKey ? 'Generate' : showDataAccessKey ? 'Hide' : 'Show'}
            </button>
          </div>
          <div className="ui segment">
            <h3 data-testid="audio-expiry-header">Audio File Expiry</h3>
            <Select
              data-testid="audio-expiry-dropdown"
              options={audioExpiryOptions}
              value={audioExpiryDays}
              onChange={(option) =>
                handleModalChange(
                  { audio_expiry_days: option.value },
                  `Change - Set audio expiry to ${option.value} days`
                )
              }
              isClearable={false}
            />
          </div>
        </>
      )}
    </>
  )
}
