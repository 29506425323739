import React from 'react'
import classNames from 'classnames'
import { isNil, isEmpty } from 'lodash'

// Only works for nested select at the moment
export const NestedSelect = ({ label, field: { name, value }, required, ...props }) => {
  const noneSelected = isNil(value) || isEmpty(value?.selected)

  return (
    <div
      className={classNames('field', { [props.className]: props.className })}
      data-testid={`${name}-read-only`}
    >
      {label && <label htmlFor={name}>{label} (Read-Only)</label>}
      {noneSelected ? (
        <div>None selected</div>
      ) : (
        <div>
          <div className="muted-text">{value.includes ? 'Includes:' : 'Does not include:'}</div>
          {value.selected.map((entry) => {
            const entryArr = entry.split('-')
            entryArr.shift()
            const entryString = entryArr.join('')

            return <div>{entryString}</div>
          })}
        </div>
      )}
    </div>
  )
}
