import React, { useState } from 'react'
import { Button, Dimmer, TransitionablePortal } from 'semantic-ui-react'
import { IconX } from '@tabler/icons-react'
import classNames from 'classnames'

import './Drawer.scss'

export const Drawer = ({ title, trigger, wide, children }) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      {React.cloneElement(trigger, { onClick: handleOpen, 'data-testid': 'drawer-trigger-button' })}

      <TransitionablePortal
        closeOnDocumentClick={false}
        open={open}
        transition={{ animation: 'fade', duration: '400' }}
      >
        <Dimmer page active onClick={handleClose} style={{ zIndex: 996 }} />
      </TransitionablePortal>

      <TransitionablePortal
        onClose={handleClose}
        open={open}
        closeOnDocumentClick={false}
        transition={{ animation: 'fly left', duration: '400' }}
      >
        <aside className={classNames('drawer', { wide })} data-testid="drawer">
          {title && (
            <div className="drawer-title">
              {title}
              <Button
                icon
                compact
                basic
                onClick={handleClose}
                className="svg-button"
                data-testid="drawer-close-button"
              >
                <IconX />
              </Button>
            </div>
          )}
          <div className="drawer-content">{React.cloneElement(children, { handleClose })}</div>
        </aside>
      </TransitionablePortal>
    </>
  )
}
