import React from 'react'
import Select from 'react-select'
import classNames from 'classnames'

import { multiSelectStyles, selectTheme, Option, MultiValue } from '../../helpers/selectHelpers'

export const MultiSelect = ({
  label,
  isDisabled,
  fixedWidth,
  options,
  field: { name, value },
  form: { setFieldValue },
  condenseOptions = true,
  showFullOptions = false,
  maxUnCondensedOptions = 0,
  required,
  loading,
  customOnChange,
  ...props
}) => {
  if (maxUnCondensedOptions) {
    condenseOptions = props.value?.length > maxUnCondensedOptions
  }
  const styles = multiSelectStyles(fixedWidth, condenseOptions, showFullOptions)

  const handleChange = (option) => {
    setFieldValue(name, option)

    if (customOnChange) {
      customOnChange(option)
    }
  }

  return (
    <div
      className={classNames('field', {
        [props.className]: props.className,
        required,
      })}
      data-testid={`${name}-multi-select`}
      {...props}
    >
      <label htmlFor={name}>{label}</label>
      <Select
        isClearable
        isSearchable
        isMulti
        closeMenuOnSelect={false}
        escapeClearsValue={false}
        hideSelectedOptions={false}
        backspaceRemovesValue={false}
        options={options}
        onChange={handleChange}
        value={value}
        isDisabled={isDisabled}
        selection
        clearable
        styles={styles}
        theme={(theme) => selectTheme(theme)}
        components={condenseOptions ? { Option, MultiValue } : { Option }}
        inputId={name}
        isLoading={loading}
        {...props}
      />
    </div>
  )
}
