import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import moment from 'moment/moment'
import { ExternalLink } from '@/components/ExternalLink'
import { formatTime } from '@/utils/helpers'
import { Popup } from 'semantic-ui-react'
import { truncate, isEmpty } from 'lodash'
import WidgetContext from '@/components/widget/widget.context'
import { Pill } from '@/components/pills/Pill'
import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'

import './QAActivity.css'

export const QAActivityTable = () => {
  const { handleChangeCsvData } = useContext(WidgetContext)
  const { data, loading } = useSelector((state) => state.scorecards)
  const { organizationid } = useSelector((state) => state.currentUser)
  const [activityEvents, setActivityEvents] = useState([])

  const compareTime = (timeStart, timeEnd) => {
    const startTime = moment(timeStart)
    const stopTime = moment(timeEnd)
    return Math.round(stopTime.diff(startTime, 'minutes', true)).toString()
  }

  const parseScore = (score) => {
    if (score === 1) {
      return {
        as: (
          <Pill small success>
            Pass
          </Pill>
        ),
        value: 'Pass',
      }
    }

    if (score === 0) {
      return {
        as: (
          <Pill small critical>
            Fail
          </Pill>
        ),
        value: 'Fail',
      }
    }

    return {
      as: <Pill small>N/A</Pill>,
      value: 'N/A',
    }
  }

  const trimText = (text) => {
    return truncate(text, {
      length: 50,
      omission: '...',
    })
  }

  const columns = [
    {
      accessor: 'new_criteria_score',
      label: 'New Score',
      collapsing: true,
    },
    {
      accessor: 'previous_criteria_score',
      label: 'Previous Score',
      collapsing: true,
    },
    {
      accessor: 'scorecard_name',
      label: 'Scorecard',
    },
    {
      accessor: 'scorecard_user',
      label: 'Call User',
      isSearchable: true,
    },
    {
      accessor: 'scorecard_note_text',
      label: 'Scorecard Note',
      isSearchable: true,
      collapsing: true,
    },
    {
      accessor: 'scorecard_note_author',
      label: 'Scorecard Note Author',
      isSearchable: true,
      collapsing: true,
    },
    {
      accessor: 'criteria_name',
      label: 'Criteria',
      isSearchable: true,
      collapsing: true,
    },
    {
      accessor: 'criteria_note_text',
      label: 'Criteria Note',
      isSearchable: true,
      collapsing: true,
    },
    {
      accessor: 'criteria_note_author',
      label: 'Criteria Note Author',
      isSearchable: true,
      collapsing: true,
    },
    {
      accessor: 'ai_explanation',
      label: 'AI Explanation',
      isSearchable: true,
      collapsing: true,
    },
    {
      accessor: 'call_start_time',
      label: 'Call Time',
    },
    {
      accessor: 'duration',
      label: 'Duration',
    },
    {
      accessor: 'editor_user',
      label: 'Editor User',
      isSearchable: true,
    },
    {
      accessor: 'created_at',
      label: 'Edited At',
    },
    {
      accessor: 'prompt_has_changed',
      label: 'Prompt Changed',
    },
    {
      accessor: 'exploreCall',
      label: 'Explore Call',
    },
  ]

  if (organizationid === 1) {
    // We shouldn't expose this to other users
    const targetColumnIndex = columns.findIndex((column) => column.accessor === 'ai_explanation')
    columns.splice(targetColumnIndex, 0, {
      accessor: 'ai_response',
      label: 'AI Response',
      isSearchable: true,
      collapsing: true,
    })
  }

  useEffect(() => {
    if (!isEmpty(data?.criteriaAuditEvents)) {
      const updatedActivityEvents = data?.criteriaAuditEvents.map((audit_event) => {
        let ai_response

        if (organizationid === 1) {
          ai_response = {
            as: (
              <div>
                <Popup
                  content={audit_event.ai_response}
                  trigger={<div>{trimText(audit_event.ai_response)}</div>}
                />
              </div>
            ),
            value: audit_event.ai_response,
          }
        }

        return {
          ...audit_event,
          scorecard_user: `${audit_event.first_name} ${audit_event.last_name}`,
          editor_user: `${audit_event.editor_first_name} ${audit_event.editor_last_name}`,
          duration: {
            as: (
              <div>
                {compareTime(audit_event.call_start_time, audit_event.call_end_time)} Minutes
              </div>
            ),
            value: parseInt(compareTime(audit_event.start_time, audit_event.end_time), 10),
          },
          exploreCall: {
            as: (
              <ExternalLink
                url={`../call-explorer/${audit_event.call_id}`}
                label="Explore Call"
                id={audit_event.call_id}
              />
            ),
            value: audit_event.call_id,
          },
          call_start_time: formatTime(audit_event.call_start_time),
          created_at: formatTime(audit_event.created_at),
          previous_criteria_score: parseScore(audit_event.previous_criteria_score),
          new_criteria_score: parseScore(audit_event.new_criteria_score),
          scorecard_note_text: {
            as: (
              <div>
                <Popup
                  content={audit_event.scorecard_note_text}
                  trigger={<div>{trimText(audit_event.scorecard_note_text)}</div>}
                />
              </div>
            ),
            value: audit_event.scorecard_note_text,
          },
          scorecard_note_author: `${audit_event?.scorecard_note_author_first_name} ${audit_event?.scorecard_note_author_last_name}`,
          criteria_note_text: {
            as: (
              <div>
                <Popup
                  content={audit_event?.criteria_note_text || ''}
                  trigger={<div>{trimText(audit_event?.criteria_note_text)}</div>}
                />
              </div>
            ),
            value: audit_event.criteria_note_text || '',
          },
          criteria_note_author:
            `${audit_event?.criteria_note_author_first_name} ${audit_event?.criteria_note_author_last_name}` ||
            '',
          ai_explanation: {
            as: (
              <div>
                <Popup
                  content={audit_event.ai_explanation}
                  trigger={<div>{trimText(audit_event.ai_explanation)}</div>}
                />
              </div>
            ),
            value: audit_event.ai_explanation,
          },
          ai_response,
          prompt_has_changed: {
            as: <div>{audit_event.prompt_has_changed ? 'Yes' : 'No'}</div>,
          },
        }
      })

      const csvData = {
        headers: columns,
        data: updatedActivityEvents.map((row) => ({
          ...row,
          previous_criteria_score: row.previous_criteria_score?.value,
          new_criteria_score: row.new_criteria_score?.value,
          scorecard_note_text: row.scorecard_note_text?.value,
          scorecard_note_author: row.scorecard_note_author,
          criteria_note_text: row.criteria_note_text?.value,
          criteria_note_author: row.criteria_note_author,
          ai_response: organizationid === 1 && row.ai_response?.value,
          ai_explanation: row.ai_explanation?.value,
          duration: row.duration?.value,
          exploreCall: row.exploreCall?.value,
        })),
      }

      handleChangeCsvData(csvData)
      setActivityEvents(updatedActivityEvents)
    } else {
      handleChangeCsvData({})
      setActivityEvents([])
    }
  }, [data?.criteriaAuditEvents])

  return (
    <div data-testid="scorecard-exports__table" className="scorecard-audit__table">
      <AdvancedTable
        rows={activityEvents}
        columns={columns}
        loading={loading?.criteriaAuditEvents}
        loadingText="Your data for this report is running. This could take a few minutes..."
        defaultOrder="asc"
        wrapColumnContent={false}
        pagination
      />
    </div>
  )
}
