import { toast } from 'react-toastify'

import { apiService, fetchingAPI } from '@/api'
import { addPromptTestHelper, deletePromptTestHelper } from '@/reducers/prompts/prompts.helpers'
import { setPrompts, setLoading, setModelOptions } from './prompts.redux'

export const fetchModels = () => async (dispatch) => {
  dispatch(setLoading({ modelOptions: true }))

  try {
    const models = await fetchingAPI(
      `${apiService.summary}/models?feature=qa_copilot`,
      'GET',
      dispatch
    )
    const modelOptions = models.llm_model_options.map((option) => ({
      value: option.name,
      label: option.display_name,
    }))

    dispatch(setModelOptions(modelOptions))
  } catch (err) {
    toast.error('Failed to fetch models')
  } finally {
    dispatch(setLoading({ modelOptions: false }))
  }
}

export const fetchPrompts = (promptIds) => async (dispatch) => {
  dispatch(setLoading({ prompts: true }))

  try {
    const prompts = await Promise.all(
      promptIds.map((promptId) =>
        fetchingAPI(`${apiService.summary}/prompts/${promptId}`, 'GET', dispatch)
      )
    )
    dispatch(setPrompts(prompts))
  } catch (err) {
    toast.error('Failed to fetch prompts')
  } finally {
    dispatch(setLoading({ prompts: false }))
  }
}

export const createPromptTest = (prompts, prompt, promptTest) => async (dispatch) => {
  try {
    const body = JSON.stringify({ ...promptTest, action: 'create' })
    const promptTestResponse = await fetchingAPI(
      `${apiService.summary}/prompts/${prompt.id}/tests`,
      'POST',
      dispatch,
      body
    )
    dispatch(setPrompts(addPromptTestHelper(prompts, prompt, promptTestResponse)))
  } catch (err) {
    toast.error('Failed to create test')
  }
}

export const deletePromptTest = (prompts, prompt, promptTestId) => async (dispatch) => {
  try {
    const promptTestResponse = await fetchingAPI(
      `${apiService.summary}/prompts/${prompt.id}/tests/${promptTestId}`,
      'DELETE',
      dispatch
    )
    dispatch(setPrompts(deletePromptTestHelper(prompts, prompt, promptTestResponse)))
  } catch (err) {
    toast.error('Failed to delete test')
  }
}

export const getPromptTestResults = (promptId, tempPrompt) => async (dispatch) => {
  dispatch(setLoading({ promptTests: true }))
  try {
    const body = JSON.stringify({ ...tempPrompt, action: 'execute' })
    return await fetchingAPI(
      `${apiService.summary}/prompts/${promptId}/tests`,
      'POST',
      dispatch,
      body
    )
  } catch (err) {
    toast.error('Failed to run tests')
    return []
  } finally {
    dispatch(setLoading({ promptTests: false }))
  }
}

export const getSinglePromptTestResults =
  (promptId, tempPrompt, expectedResponse, callId, description) => async (dispatch) => {
    dispatch(setLoading({ promptTests: true }))
    try {
      const body = JSON.stringify({
        ...tempPrompt,
        action: 'execute',
        expected_response: expectedResponse,
        inputs: { call_id: callId },
        description,
      })

      return await fetchingAPI(
        `${apiService.summary}/prompts/${promptId}/temp_tests`,
        'POST',
        dispatch,
        body
      )
    } catch (err) {
      toast.error('Failed to run tests')
      return null
    } finally {
      dispatch(setLoading({ promptTests: false }))
    }
  }

export const updatePromptTestDescription =
  (promptId, promptTestId, newDescription) => async (dispatch) => {
    try {
      const body = JSON.stringify({ description: newDescription })

      await fetchingAPI(
        `${apiService.summary}/prompts/${promptId}/tests/${promptTestId}`,
        'PUT',
        dispatch,
        body
      )
    } catch (err) {
      toast.error('Failed to update description')
    }
  }
