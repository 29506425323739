import React from 'react'
import { Popup } from 'semantic-ui-react'

export const FilterTypePopup = ({
  open,
  setOpen,
  trigger,
  children,
  position,
  popupProps = {},
}) => {
  return (
    <Popup
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      className="filter-pill__popup"
      on="click"
      position={position}
      trigger={<div className="filter-pill__trigger">{trigger}</div>}
      {...popupProps}
    >
      {children}
    </Popup>
  )
}
