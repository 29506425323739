import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { Button, Grid, Loader, Segment } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { first, isEmpty } from 'lodash'
import moment from 'moment'
import classNames from 'classnames'
import { IconCalendar, IconPhone, IconDownload, IconArrowRight } from '@tabler/icons-react'

import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import { ExternalLink } from '@/components/ExternalLink'
import { formatTime } from '@/utils/helpers'
import NoData from '@/components/NoData'
import { setData } from '@/reducers/scorecards/scorecards.redux'
import {
  fetchAggregateScoresByConfigId,
  fetchAggregateScores,
  fetchNotableCalls,
  fetchScoresByScorecardId,
  removeSelectedScorecard,
  selectScorecard,
} from '@/reducers/scorecards/scorecards.actions'
import { calculatePercentage } from '../CallScoreCarousel/helpers'
import { MeasureScoresTable } from '../MeasureScoresTable/MeasureScoresTable'

import { returnScoreColor, ScoreAccordion } from '../ScoreAccordion/ScoreAccordion'
import { CallScoreCarousel } from '../CallScoreCarousel/CallScoreCarousel'
import { ViewScoresFilters } from '../ViewScoresFilters/ViewScoresFilters'

import './viewScoresPage.css'

const ABOVE_THRESHOLD = 'aboveThreshold'
const BELOW_THRESHOLD = 'belowThreshold'
const columns = [
  {
    accessor: 'start_time',
    label: 'Date and Time',
  },
  {
    accessor: 'name',
    label: 'Name',
  },
  {
    accessor: 'duration',
    label: 'Duration',
  },
  {
    accessor: 'aggregate_score',
    label: 'Call Score',
  },
  {
    accessor: 'voip_call_id',
    label: 'VoIP ID',
  },
  {
    accessor: 'voip_customer_id',
    label: 'VoIP Customer Id',
  },
  {
    accessor: 'exploreCall',
    label: 'Explore Call',
  },
]

const ViewScoresMetadata = ({ dateRange, numScores, displayScorecards, showTableText }) => (
  <div className="view-scores-page__metadata" data-testid="view-scores-metadata">
    <div className="view-scores-page__metadata-left">
      <span className="view-scores-page__date-range">
        <IconCalendar className="icon-svg" />
        {dateRange}
      </span>
      <span className="view-scores-page__total-calls">
        <IconPhone className="icon-svg" />
        {numScores} calls
      </span>
    </div>
    <div className="view-scores-page__metadata-right">
      <Button
        icon
        secondary
        className="svg-button"
        data-testid="view-all-calls"
        onClick={() => displayScorecards()}
      >
        {showTableText}
        <IconArrowRight />
      </Button>
    </div>
  </div>
)

export const NoScoresMessage = () => {
  return (
    <div data-testid="no-scores" className="view-scores-page__no-scores-container">
      <div className="empty-table">
        <NoData />
      </div>
    </div>
  )
}

export const ViewScoresLoader = () => {
  return (
    <div data-testid="view-scores-loader" className="view-scores-page__loader-container">
      <Loader active data-testid="loader" />
    </div>
  )
}

export const PrintScoresComponent = ({
  orgName,
  filters,
  aggregateScores,
  numScores,
  targetScorecardConfig,
}) => {
  const startDate = moment(filters?.startDate).local().format('MM/DD/YYYY')
  const endDate = moment(filters?.endDate).local().format('MM/DD/YYYY')
  const allAgents = filters.agents.map((agent) => agent.label)
  const allTags = filters.tags.map((tag) => tag.label)
  const successClass = returnScoreColor(aggregateScores.percentage_score, targetScorecardConfig)
  return (
    <div>
      <div className="view-scores-page_print_details">
        <Grid.Row>
          <h1 style={{ textAlign: 'center' }}>{orgName} Scores</h1>
        </Grid.Row>
        <Grid.Row>
          <strong>Date Range:</strong> {startDate} - {endDate}
        </Grid.Row>
        <Grid.Row>
          <strong>Scorecard Config:</strong> {filters?.scorecards?.label}
        </Grid.Row>
        <Grid.Row>
          <strong>Agents:</strong> {allAgents.join(', ')}
        </Grid.Row>
        <Grid.Row>
          <strong>Tags:</strong> {allTags.join(', ')}
        </Grid.Row>
        <Grid.Row>
          <div style={{ float: 'left' }}>Total Calls: {numScores}</div>
          <div className={`view-scores-page__overall-score ${successClass}`}>
            Score Total: {aggregateScores.percentage_score}%
          </div>
        </Grid.Row>
      </div>
    </div>
  )
}
export const ViewScoresPageContent = ({ printScores, toggleTable, showTable, copilot }) => {
  const dispatch = useDispatch()
  const { data, loading } = useSelector((state) => state.scorecards)
  const [selectedScorecards, setSelectedScorecards] = useState([])
  const [aboveThreshold, setAboveThreshold] = useState('')
  const [belowThreshold, setBelowThreshold] = useState('')
  const { aggregateScores } = data
  const { targetScorecardConfig } = data
  const { section_scores: sectionScores } = aggregateScores
  const closeCarousel = () => {
    dispatch(removeSelectedScorecard())
    dispatch(setData('displayNotableCalls', false))
  }

  const displayScorecards = () => {
    if (!showTable) {
      toggleTable(true)
      dispatch(fetchScoresByScorecardId())
    } else {
      toggleTable(false)
    }
  }

  const getNotableCalls = (section, threshold) => {
    dispatch(fetchNotableCalls(section, threshold))
  }

  const updateThresholdState = (threshold) => {
    if (threshold === ABOVE_THRESHOLD) {
      setAboveThreshold('above-threshold')
      setBelowThreshold('')
    } else {
      setBelowThreshold('below-threshold')
      setAboveThreshold('')
    }
  }
  const displayNotableCalls = (section, threshold, testValue = '') => {
    updateThresholdState(testValue)
    dispatch(setData('displayNotableCalls', true))
    setSelectedScorecards([section])
    dispatch(selectScorecard(first(threshold[section.section_id])))
    const notableScoreIds = threshold[section.section_id]?.map((score) => score.id)
    dispatch(setData('scores', notableScoreIds))
  }

  const updateThresholdText = (sectionData) => {
    if (
      selectedScorecards.length > 0 &&
      sectionData.section_id === first(selectedScorecards)?.section_id &&
      data.displayNotableCalls
    ) {
      return 'active'
    }
    return ''
  }

  const renderNotableCallsHeader = (sectionData) => {
    if (loading.notableCalls) {
      return <Loader active inline data-testid="loader" size="mini" />
    }
    const shouldHighlight = updateThresholdText(sectionData)
    if (
      data.aboveThreshold[sectionData.section_id]?.length > 0 ||
      data.belowThreshold[sectionData.section_id]?.length > 0
    ) {
      return (
        <div className="view-scores-page_notable_call_section">
          <div className="view-scores-page_notable_calls" data-testid="view-notable-calls-length">
            Notable Calls
          </div>
          <Button.Group className="view-scores-page_button-group">
            <Button
              className={`view-scores-page ${shouldHighlight} ${belowThreshold}`}
              data-testid="view-notable-calls"
              onClick={() => displayNotableCalls(sectionData, data.belowThreshold, BELOW_THRESHOLD)}
              disabled={data.belowThreshold[sectionData.section_id]?.length === 0}
            >
              Below Threshold ({`${data.belowThreshold[sectionData.section_id]?.length}`})
            </Button>
            <Button
              className={`view-scores-page ${shouldHighlight} ${aboveThreshold}`}
              data-testid="view-notable-calls_above_threshold"
              onClick={() => displayNotableCalls(sectionData, data.aboveThreshold, ABOVE_THRESHOLD)}
              disabled={data.aboveThreshold[sectionData.section_id]?.length === 0}
            >
              Above Threshold ({`${data.aboveThreshold[sectionData.section_id]?.length}`})
            </Button>
          </Button.Group>
        </div>
      )
    }
    return <div data-testid="no-notable-calls" />
  }

  const sectionAccordions =
    sectionScores &&
    Object.entries(sectionScores).map(([sectionName, sectionData]) => {
      const { measure_scores: measureScores } = sectionData
      const measureRows = Object.entries(measureScores).map(([name, measureData]) => ({
        name,
        aggregate_score: measureData.aggregate_score,
        possible_score: measureData.possible_score,
        percentage_score: measureData.percentage_score,
        measure_id: measureData.measure_id,
      }))

      return (
        <ScoreAccordion
          title={sectionName}
          score={sectionData.percentage_score}
          key={sectionName}
          withColor
          sectionData={sectionData}
          getNotableCalls={getNotableCalls}
          aggregateSection
          showAllAccordions={printScores}
        >
          <MeasureScoresTable measureScores={measureRows} copilot={copilot} />
          {renderNotableCallsHeader(sectionData)}
        </ScoreAccordion>
      )
    })
  const successClass = returnScoreColor(aggregateScores.percentage_score, targetScorecardConfig)
  const startDate = moment(data?.aggregateFilters?.start_date).local().format('MM/DD/YYYY')
  const endDate = moment(data?.aggregateFilters?.end_date).local().format('MM/DD/YYYY')
  const dateRange = `${startDate} - ${endDate}  `
  const scoresLength = data.allScores?.length || 0
  const showTableText =
    showTable && data.scorecardConfigs?.length > 0 ? 'Hide All Calls' : 'Show All Calls'

  if (isEmpty(aggregateScores) || scoresLength === 0) {
    return <NoScoresMessage />
  }

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <ViewScoresMetadata
            dateRange={dateRange}
            numScores={scoresLength}
            displayScorecards={displayScorecards}
            showTableText={showTableText}
          />
        </Grid.Column>
      </Grid.Row>

      {!isEmpty(aggregateScores) ? (
        <Grid.Row
          className="view-scores-page__metrics-section"
          data-testid="view-scores-metrics-section"
        >
          <Grid.Column width={8} className="view-scores-page__overall-metrics">
            {sectionAccordions}
            <span className="view-scores-page__overall-score-section">
              <h3>
                Aggregate Score:
                <span
                  className={classNames({
                    'status-success': successClass === 'exceeds_threshold',
                    'status-warning': successClass === 'meets_threshold',
                    'status-caution': successClass === 'improvement_threshold',
                    'status-critical': successClass === 'failure',
                  })}
                  style={{ marginLeft: '0.5rem' }}
                  data-testid="overall-score"
                >
                  {`${aggregateScores.percentage_score}%`}
                </span>
              </h3>
            </span>
          </Grid.Column>
          <Grid.Column width={6}>
            {first(data.selectedScorecards)?.id && (
              <CallScoreCarousel
                callScoreIds={data.scores}
                closeCarousel={closeCarousel}
                copilot={copilot}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      ) : (
        <Grid.Row>
          <span className={`view-scores-page__overall-score ${successClass}`}>
            Aggregate Score is unavailable until a call is scored. Expand calls and use the Call
            Explorer to score a call.
          </span>
        </Grid.Row>
      )}
    </Grid>
  )
}

export const ViewScoresPage = ({ copilot = false }) => {
  const dispatch = useDispatch()
  const { data, loading, filters } = useSelector((state) => state.scorecards)
  const { targetScorecardConfig } = data
  // Copy this and pass it to this component
  const [showScoresTable, setShowScoresTable] = useState(false)
  const [printScores, setPrintScores] = useState(false)
  const { name: orgName } = useSelector((state) => state.organization)
  const { aggregateScores } = data
  const scoresLength = data.allScores?.length || 0

  function getAggregateData() {
    const scorecardId = first(filters.scorecards)?.value
    if (scorecardId) {
      dispatch(fetchAggregateScoresByConfigId(scorecardId))
    } else {
      dispatch(fetchAggregateScores())
      dispatch(setData('displayNotableCalls', false))
    }
  }

  const toggleShowTable = (bool) => {
    setShowScoresTable(bool)
  }
  const compareTime = (timeStart, timeEnd) => {
    const startTime = moment(timeStart)
    const stopTime = moment(timeEnd)
    return Math.round(stopTime.diff(startTime, 'minutes', true)).toString()
  }

  const openCarousel = (scorecard) => {
    dispatch(selectScorecard(scorecard))
    dispatch(setData('scores', data.allScores))
    dispatch(setData('displayNotableCalls', false))
  }

  const handleFilterSubmit = async () => {
    setShowScoresTable(false)
    getAggregateData()
  }

  useEffect(() => {
    if (!isEmpty(filters.scorecards)) {
      getAggregateData()
    }
  }, [filters.scorecards])

  const formatScoresData = (scorecardData) => {
    return scorecardData.map((scorecard) => {
      const percentage = calculatePercentage(scorecard.aggregate_score, scorecard.possible_score)
      const displayAggregateScore = scorecard.aggregate_score !== null ? `${percentage}%` : 'N/A'

      return {
        ...scorecard,
        aggregate_score: {
          as: (
            <div
              data-testid="view-all-calls-percentage"
              className="view-scores-page_click"
              onClick={() => openCarousel(scorecard)}
            >
              {displayAggregateScore}
            </div>
          ),
          value: (scorecard.aggregate_score / scorecard.possible_score) * 100,
        },
        start_time: {
          as: (
            <div
              data-testid="view-all-calls-start-time"
              className="view-scores-page_click"
              onClick={() => openCarousel(scorecard)}
            >
              {formatTime(scorecard.start_time)}
            </div>
          ),
          value: scorecard.start_time,
        },
        name: {
          as: (
            <div
              data-testid="view-all-calls-user_name"
              className="view-scores-page_click"
              onClick={() => openCarousel(scorecard)}
            >
              {scorecard.user_first_name} {scorecard.user_last_name}
            </div>
          ),
          value: `${scorecard.user_first_name} ${scorecard.user_last_name}`,
        },
        duration: {
          as: (
            <div
              data-testid="view-all-calls-call-duration"
              className="view-scores-page_click"
              onClick={() => openCarousel(scorecard)}
            >
              {compareTime(scorecard.start_time, scorecard.end_time)} Minutes
            </div>
          ),
          value: parseInt(compareTime(scorecard.start_time, scorecard.end_time), 10),
        },
        voip_call_id: {
          as: (
            <div
              data-testid="view-all-calls-table-voip-id"
              className="view-scores-page_click"
              onClick={() => openCarousel(scorecard)}
            >
              {scorecard.voip_call_id}
            </div>
          ),
          value: scorecard.voip_call_id,
        },
        voip_customer_id: {
          as: (
            <div
              data-testid="view-all-calls-table-voip-customer-id"
              className="view-scores-page_click"
              onClick={() => openCarousel(scorecard)}
            >
              {scorecard.voip_customer_id}
            </div>
          ),
          value: scorecard.voip_customer_id,
        },
        exploreCall: {
          as: (
            <ExternalLink
              url={`../call-explorer/${scorecard.call_id}`}
              label="Explore Call"
              id={scorecard.call_id}
            />
          ),
          value: scorecard.call_id,
        },
      }
    })
  }

  const handlePrint = async () => {
    await dispatch(fetchScoresByScorecardId())
    toggleShowTable(true)
    setPrintScores(true)
  }
  useEffect(() => {
    printScores ? window.print() : setPrintScores(false)
  }, [printScores])
  const formattedRows = formatScoresData(data?.filteredScores)
  window.onafterprint = () => setPrintScores(false)

  return (
    <>
      <header className="page-header">
        <h1>Scores</h1>
        {!isEmpty(data.aggregateScores) ? (
          <div className="flex-align-center small-gap">
            <Button
              icon
              secondary
              className="svg-button"
              data-testid="print-button"
              onClick={handlePrint}
            >
              <IconDownload />
              Print
            </Button>
          </div>
        ) : null}
      </header>
      <ViewScoresFilters
        handleFilterSubmit={handleFilterSubmit}
        hide={{
          sections: true,
          measures: true,
          criteria: true,
        }}
      />
      {printScores && (
        <PrintScoresComponent
          orgName={orgName}
          filters={filters}
          numScores={scoresLength}
          aggregateScores={aggregateScores}
          targetScorecardConfig={targetScorecardConfig}
        />
      )}

      <Segment className="not-padded">
        <Grid className="view-scores-page" data-testid="view-scores-page">
          <Grid.Column width={16}>
            {loading.scores ? (
              <ViewScoresLoader />
            ) : (
              <ViewScoresPageContent
                printScores={printScores}
                showTable={showScoresTable}
                toggleTable={toggleShowTable}
                copilot={copilot}
              />
            )}
          </Grid.Column>
        </Grid>
        {showScoresTable && (
          <div className="view-scores-page__table-container">
            {loading.scoresTable ? (
              <Loader
                active
                inline
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              />
            ) : (
              <AdvancedTable
                loading={loading.scorecards}
                rows={formattedRows}
                columns={columns}
                pagination={!printScores}
              />
            )}
          </div>
        )}
      </Segment>
    </>
  )
}

export default withRouter(ViewScoresPage)
