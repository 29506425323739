import React, { useEffect, useMemo, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import { IconX } from '@tabler/icons-react'

import { setCallExplorerData } from '@/reducers/callSearch/callSearch.redux'

import { Pill } from '@/components/pills/Pill'
import { Banner } from '@/components/banners/Banner'
import {
  CALL_EXPLORER_CRITERIA_FILTER_ENUMS as enums,
  filterCriteriaScore,
  parseCallExplorerParams,
} from './helpers'
import { QAScoreEditCriteria } from './QAScoreEditCriteria'
import { QAScoreShortcuts } from './QAScoreShortcuts'

export const constructFlatMapCriteria = (score) => {
  return (criteriaFilter) => {
    const allCriteriaOrdered = score.section_scores.flatMap((sectionScore) =>
      sectionScore.measure_scores.flatMap((measureScore) =>
        measureScore.criteria_scores.map((criteriaScore) => ({
          ...criteriaScore,
          sectionId: sectionScore.id,
          measureId: measureScore.id,
        }))
      )
    )

    return allCriteriaOrdered.filter((criteria) => filterCriteriaScore(criteria, criteriaFilter))
  }
}

export const QAScoreEditContent = ({ score, callId, formikProps, handleSubmit }) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const { selectedCriteriaFilter, selectedCriteriaFocusIndex } = useSelector(
    (state) => state.callSearch.callExplorer
  )
  const criteriaRef = useRef([])

  const flatMapCriteria = constructFlatMapCriteria(score)

  const filteredCriteriaOrdered = useMemo(() => {
    return flatMapCriteria(selectedCriteriaFilter)
  }, [score, selectedCriteriaFilter])

  const filteredSections = score.section_scores.filter((sectionScore) =>
    filteredCriteriaOrdered.find((criteria) => criteria.sectionId === sectionScore.id)
  )

  const disputedCriteria = filteredCriteriaOrdered.filter(
    (criteria) => criteria?.dispute?.status === 'pending'
  )
  const disputedCriteriaCount = disputedCriteria.length

  const handleChangeScore = (value) => {
    const updatedValue = { ...formikProps.values, ...value }

    formikProps.setValues(updatedValue)
  }

  useEffect(() => {
    // criteriaRef is an array of all the refs for each criteria Dropdown
    if (criteriaRef.current[selectedCriteriaFocusIndex]?.ref?.current) {
      criteriaRef.current[selectedCriteriaFocusIndex].ref.current.focus()
    }
  }, [selectedCriteriaFocusIndex])

  useEffect(() => {
    const { criteriaScoreId, scoreFilter } = parseCallExplorerParams(location)
    // the filter might not be loaded into the redux store yet, so we pull it manually to get the correct index
    const orderedFilteredCriteria = flatMapCriteria(scoreFilter || enums.ALL)

    if (criteriaScoreId) {
      const criteriaIndex = orderedFilteredCriteria.findIndex(
        (criteria) => criteria.id === Number(criteriaScoreId)
      )

      dispatch(setCallExplorerData({ selectedCriteriaFocusIndex: criteriaIndex }))
    }
  }, [])

  return (
    <div className="qa-scores-container">
      <QAScoreShortcuts
        handleChangeScore={handleChangeScore}
        filteredCriteriaOrdered={filteredCriteriaOrdered}
        handleSubmit={handleSubmit}
      />
      {!isEmpty(disputedCriteria) && (
        <Banner
          caution
          shrunk
          header="Agent Disputes"
          content={`${disputedCriteriaCount} Criteria need attention.`}
          icon={<IconX />}
          className="mb"
          data-testid="disputed-criteria-banner"
        />
      )}
      <div className="qa-scores edit-mode">
        <div className="qa-score edit-mode">
          {isEmpty(filteredSections) ? (
            <div className="empty">No criteria available.</div>
          ) : (
            filteredSections.map((sectionScore) => {
              const {
                possible_score: sectionPossibleScore,
                aggregate_score: sectionAggregateScore,
              } = sectionScore
              return (
                <div className="score-accordion" key={sectionScore.id}>
                  <div className="score-accordion-header">
                    <h3>{sectionScore.name}</h3>
                    <div>
                      {sectionAggregateScore}/{sectionPossibleScore} points
                    </div>
                  </div>
                  <div className="score-accordion-content">
                    {sectionScore.measure_scores
                      .filter((measureScore) =>
                        filteredCriteriaOrdered.find(
                          (criteria) => criteria.measureId === measureScore.id
                        )
                      )
                      .map((measureScore) => {
                        return (
                          <div key={measureScore.id}>
                            <div className="measure-header">
                              <h4>{measureScore.name}</h4>
                              <div className="flex-align-center small-gap">
                                {measureScore.auto_zeroed && (
                                  <Pill emphasized small caution>
                                    Auto-zero
                                  </Pill>
                                )}
                                {measureScore.auto_failed && (
                                  <Pill emphasized small critical>
                                    Auto-fail
                                  </Pill>
                                )}
                                {measureScore.condition_type && (
                                  <Pill small emphasized>
                                    {measureScore.condition_type}
                                  </Pill>
                                )}
                              </div>
                            </div>
                            {measureScore.criteria_scores
                              .filter((criteriaScore) =>
                                filterCriteriaScore(criteriaScore, selectedCriteriaFilter)
                              )
                              .map((criteriaScore) => {
                                const currentFocusIndex = filteredCriteriaOrdered.findIndex(
                                  (criteria) => criteria.uuid === criteriaScore.uuid
                                )
                                return (
                                  <QAScoreEditCriteria
                                    criteriaScore={criteriaScore}
                                    score={score}
                                    callId={callId}
                                    key={criteriaScore.id}
                                    ref={criteriaRef}
                                    index={currentFocusIndex}
                                    active={currentFocusIndex === selectedCriteriaFocusIndex}
                                    formikProps={formikProps}
                                  />
                                )
                              })}
                          </div>
                        )
                      })}
                  </div>
                </div>
              )
            })
          )}
        </div>
      </div>
    </div>
  )
}
