import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { AudioPlayer } from './AudioPlayer'

export const AudioDrawer = () => {
  const { callAudio } = useSelector((state) => state.audio)
  const { isPlaying } = callAudio
  const [isHidden, setIsHidden] = useState(!isPlaying)

  const handleShowPlayer = () => {
    setIsHidden(false)
  }

  const handleHidePlayer = () => {
    setIsHidden(true)
  }

  return (
    <div
      data-testid="audio-drawer"
      className={classNames('bottom-drawer audio-drawer', { hidden: isHidden })}
    >
      <AudioPlayer
        handleShowPlayer={handleShowPlayer}
        handleHidePlayer={handleHidePlayer}
        hideAudioTrack
      />
    </div>
  )
}
