import React from 'react'
import * as Yup from 'yup'
import { Form, Button, Header } from 'semantic-ui-react'
import { Formik, Field } from 'formik'
import classNames from 'classnames'

import {
  Checkbox,
  CheckboxGroup,
  MultiSelect,
  RadioGroup,
  NestedSelect,
  Select,
  TextArea,
  TextField,
} from '.'
import { showDependentField } from './helpers'

import './AbstractForm.scss'

// Get default values, and if it's an editing form use any existing values
const getInitialValues = (initialValues, existingValues) => {
  if (!existingValues) return initialValues

  return { ...initialValues, ...existingValues }
}

const formComponents = [
  { type: 'text', component: TextField },
  { type: 'password', component: TextField },
  { type: 'email', component: TextField },
  { type: 'number', component: TextField },
  { type: 'textArea', component: TextArea },
  { type: 'select', component: Select },
  { type: 'multiSelect', component: MultiSelect },
  { type: 'radioGroup', component: RadioGroup },
  { type: 'checkbox', component: Checkbox },
  { type: 'checkboxGroup', component: CheckboxGroup },
  { type: 'header', component: Header },
  { type: 'nestedSelect', component: NestedSelect },
]

export const AbstractForm = ({
  schema,
  onSubmit,
  existingValues,
  onClose,
  buttonProps = {},
  closeButtonProps = {},
  buttonLabel = 'Submit',
  closeButtonLabel = 'Cancel',
  buttonAlignment = 'right',
  horizontal,
  onDirty = () => {},
  isModal,
  noLabel,
  ...props
}) => {
  // Set initial values
  const initialValues = schema.reduce((acc, val) => {
    let defaultValue
    switch (val.type) {
      case 'text':
      case 'password':
      case 'email':
      case 'textArea':
      case 'select':
      case 'radioGroup':
        defaultValue = ''
        break
      case 'checkboxGroup':
      case 'multiSelect':
        defaultValue = []
        break
      case 'checkbox':
        defaultValue = false
        break
      case 'nestedSelect':
        defaultValue = undefined
        break
      default:
        defaultValue = ''
    }
    return { ...acc, [val.name]: val.defaultValue || defaultValue, validateOnMount: true }
  }, {})

  // Set validation object
  const cyclicDependencyArray = []
  const validationObject = schema.reduce((acc, val) => {
    let validationType

    switch (val.type) {
      case 'text':
      case 'password':
      case 'textArea':
      case 'select':
      case 'radio':
      case 'radioGroup':
        validationType = Yup.string()
        break
      case 'email':
        validationType = Yup.string().email('Must be a valid email')
        break
      case 'number':
        validationType = Yup.number().typeError('Must be a valid number')
        break
      case 'checkboxGroup':
      case 'multiSelect':
        validationType = Yup.array()

        if (val.required) {
          validationType = validationType.min(1, `${val.label} requires at least one option`)
        }
        break
      case 'checkbox':
        if (val.required) {
          validationType = Yup.boolean().oneOf([true], `${val.label} must be selected`)
        }
        break
      case 'nestedSelect':
        validationType = null
        break
      default:
        validationType = null
    }

    if (val.required && validationType) {
      validationType = validationType.required(`${val.label} is required`)
    }

    if (val.nullable && validationType) {
      validationType = validationType.nullable(true)
    }

    if (val.customValidators) {
      val.customValidators.forEach((validation) => {
        validationType = validation
      })
      // TODO: Find a better way to handle cyclic dependencies
      if (val.cyclicDependency) {
        cyclicDependencyArray.push(val.name)
      }
    }

    return { ...acc, ...(validationType && { [val.name]: validationType }) }
  }, {})
  const validationSchema = Yup.object().shape(validationObject, cyclicDependencyArray)
  const customOnChange = (name, value, fn, onChange) => {
    if (value) {
      fn(value)
    }
    onChange(name, value)
  }
  return (
    <Formik
      initialValues={getInitialValues(initialValues, existingValues)}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnMount
      {...props}
    >
      {({ handleSubmit, isSubmitting, isValid, values, setFieldValue, dirty }) => {
        onDirty(dirty)

        return (
          <Form
            onSubmit={handleSubmit}
            data-testid="abstract-form"
            className={classNames('abstract-form', { horizontal, 'no-label': noLabel })}
          >
            {schema.map(
              ({ type, inputType, customValidators, cyclicDependency, ...formSchema }) => {
                if (formSchema.dependencies && !showDependentField(formSchema, values)) {
                  // if the field disappears, reset value we don't want a lingering value to be submitted
                  if (values[formSchema.name] !== initialValues[formSchema.name]) {
                    setFieldValue(formSchema.name, initialValues[formSchema.name])
                  }
                  return null
                }

                // for injecting customized fields or other html for display purposes
                if (formSchema.raw) {
                  return formSchema.as
                }

                if (!formComponents.some((formComponent) => formComponent.type === type))
                  return null

                const Component = formComponents.find(
                  (formComponent) => formComponent.type === type
                ).component

                return formSchema.isStatic ? (
                  <Component key={formSchema.name} as={formSchema.as || 'h1'}>
                    {formSchema.label}
                  </Component>
                ) : (
                  <Field
                    key={formSchema.name}
                    name={formSchema.name}
                    type={inputType}
                    validate={formSchema?.validate}
                  >
                    {(fieldProps) => {
                      // allows for custom logic when field value is changed
                      const customComponentProps = {}
                      if (formSchema.onChange) {
                        customComponentProps.onChange = (value) =>
                          customOnChange(
                            formSchema.name,
                            value,
                            formSchema.onChange,
                            fieldProps.field.onChange
                          )
                      }

                      return (
                        <Component
                          {...fieldProps}
                          {...formSchema}
                          {...customComponentProps}
                          type={inputType}
                        />
                      )
                    }}
                  </Field>
                )
              }
            )}
            <div
              className={classNames('form-actions', {
                'left-aligned': buttonAlignment === 'left',
                'is-modal': isModal,
              })}
            >
              {!!onClose && (
                <Button
                  secondary
                  data-testid="abstract-form-close"
                  type="button"
                  onClick={onClose}
                  {...closeButtonProps}
                >
                  {closeButtonLabel}
                </Button>
              )}
              <Button
                data-testid="abstract-form-submit"
                primary
                type="submit"
                loading={isSubmitting}
                disabled={!isValid || isSubmitting}
                {...buttonProps}
              >
                {buttonLabel}
              </Button>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
