// Action Types
export const SET_PARSED_CSV = 'organizations/setVoipParsedCsv'
export const UPDATE_UPLOAD_RESPONSE = 'organizations/updateVoipCsvUploadResponse'
export const CLEAR_CSV_AND_UPLOAD_RESPONSE = 'organizations/clearVoipCsvAndUploadResponse'

// Action Creators
export const setParsedCsv = (payload) => ({ type: SET_PARSED_CSV, payload })
export const updateUploadResponse = (payload) => ({ type: UPDATE_UPLOAD_RESPONSE, payload })
export const clearCsvAndUploadResponse = () => ({ type: CLEAR_CSV_AND_UPLOAD_RESPONSE })

// State
export const initialState = {
  parsedCSV: [],
  uploadResponse: {},
}

// Reducer
export default function organizationUsersCSVReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PARSED_CSV:
      return {
        ...state,
        parsedCSV: action.payload,
      }
    case UPDATE_UPLOAD_RESPONSE:
      return {
        ...state,
        uploadResponse: action.payload,
      }
    case CLEAR_CSV_AND_UPLOAD_RESPONSE:
      return { parsedCSV: [], uploadResponse: {} }
    default:
      return state
  }
}
