import React from 'react'
import { Popup } from 'semantic-ui-react'

export const AudioTrackProgress = ({ isDisabled, duration, progress, handleScrub }) => {
  return (
    <Popup
      inverted
      content="No Audio Available"
      disabled={!isDisabled}
      trigger={
        <div className="full-width">
          <input
            data-testid="audio-track-progress"
            type="range"
            className={{ disabled: isDisabled }}
            max={duration}
            value={progress}
            step={0.01}
            onChange={handleScrub}
            disabled={isDisabled}
          />
        </div>
      }
    />
  )
}
