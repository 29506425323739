import { apiService, fetchingAPI } from '@/api'
import { toast } from 'react-toastify'
import { isEmpty } from 'lodash'

import * as redux from './baltoGPT.redux'

export const searchCallsWithBaltoGPT = (values) => {
  return async (dispatch, getState) => {
    const { calls } = getState().callSearch
    const { chatFeed } = getState().baltoGPT

    dispatch(redux.setLoading({ baltoGPTChat: true }))
    dispatch(redux.updateChatFeed({ chatType: 'agent', text: values.prompt }))

    const valuesWithCallIds = {
      ...values,
      call_ids: calls.map((call) => call.call_id),
      conversation_history: chatFeed.map((msg) => ({
        role: msg.chatType === 'agent' ? 'user' : 'assistant',
        content: msg.text,
      })),
    }

    if (isEmpty(valuesWithCallIds)) {
      toast.error('Please make a call explorer search before using BaltoGPT')
      dispatch(redux.setLoading({ baltoGPTChat: false }))
      return
    }

    try {
      const response = await fetchingAPI(
        `${apiService.summary}/beacon/search`,
        'POST',
        dispatch,
        JSON.stringify(valuesWithCallIds)
      )

      dispatch(redux.updateChatFeed({ chatType: 'baltoGPT', text: response?.response }))
    } catch (err) {
      dispatch(
        redux.updateChatFeed({
          chatType: 'error',
          text: 'Failed to search calls with BaltoGPT. Try updating your search range or call duration.',
        })
      )
    } finally {
      dispatch(redux.setLoading({ baltoGPTChat: false }))
    }
  }
}

export const searchTranscriptWithBaltoGPT = (values) => {
  return async (dispatch, getState) => {
    const { callExplorer } = getState().callSearch
    const { chatFeed } = getState().baltoGPT

    dispatch(redux.setLoading({ baltoGPTChat: true }))
    dispatch(redux.updateChatFeed({ chatType: 'agent', text: values.prompt }))

    const updatedChatFeed = [...chatFeed, { chatType: 'agent', text: values.prompt }]

    if (isEmpty(callExplorer?.transcript)) {
      toast.error('No transcript is available for this call')
      dispatch(redux.setLoading({ baltoGPTChat: false }))
      return
    }

    try {
      const response = await fetchingAPI(
        `${apiService.summary}/beacon/search/${callExplorer.callId}`,
        'POST',
        dispatch,
        JSON.stringify({
          prompt: values.prompt,
          conversation_history: updatedChatFeed.map((msg) => ({
            role: msg.chatType === 'agent' ? 'user' : 'assistant',
            content: msg.text,
          })),
        })
      )

      dispatch(redux.updateChatFeed({ chatType: 'baltoGPT', text: response?.response }))
    } catch (err) {
      dispatch(
        redux.updateChatFeed({
          chatType: 'error',
          text: 'Failed to search transcript with BaltoGPT',
        })
      )
    } finally {
      dispatch(redux.setLoading({ baltoGPTChat: false }))
    }
  }
}

export const resetChatFeed = () => {
  return async (dispatch) => {
    dispatch(redux.reset())
  }
}
