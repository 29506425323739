import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Button, Input } from 'semantic-ui-react'
import { isEmpty } from 'lodash'
import format from 'date-fns/format'
import { differenceInDays } from 'date-fns'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { IconPlus } from '@tabler/icons-react'

import { DateRangePickerReusable } from '@/components/datePickers/DateRangePickerReusable'
import Select from '@/components/forms/Select'
import { MultiSelect } from '@/components/forms/MultiSelect'
import { NestedSelect } from '@/components/forms/NestedSelect'
import {
  fetchCalls,
  fetchAgentsByOrg,
  fetchTagsByOrg,
  fetchPlaybooksByOrg,
  fetchPlaybookData,
  fetchDispositionsByOrg,
  clearAllFiltersAndFetchCalls,
  fetchCallsFromLatestFilters,
} from '@/reducers/callSearch/callSearch.actions'
import {
  initialState,
  setFilters,
  setDateRangeFilters,
  setData,
} from '@/reducers/callSearch/callSearch.redux'
import { formatNestedOptions, formatFlatOptions } from '@/components/helpers/nestedSelectHelpers'
import { getOrganizationOptions, getHierarchyOrganizationOptions } from '@/utils/helpers'
import { minCallDurationOptions } from '@/utils/constants'
import { ErrorMessage } from '@/components/forms/ErrorMessage'
import { OrganizationFilter } from '@/components/filters/OrganizationFilter'
import { WinsFilter } from '@/components/filters/WinsFilter'
import { BasicModal } from '@/components/layout/modals/BasicModal'
import { ConditionalTooltip } from '@/components/ConditionalTooltip'
import { CALL_EXPLORER_FILTER_TYPE } from '@/reducers/savedFilters/savedFilters.constants'
import { saveFilter } from '@/reducers/savedFilters/savedFilters.actions'
import {
  getCallExplorerFilterDisplayValues,
  callExplorerValueHasChanged,
} from '@/reducers/callSearch/helpers'
import { getDateRangeLabel } from '@/components/helpers'

import { KeywordSearch } from './components/KeywordSearch'
import { CallSearchPills } from './components/CallSearchPills'
import { SaveCallFilter } from './components/SaveCallFilter'

const CallSearchFiltersPage = ({ flags, params, childOrgSelected }) => {
  const dispatch = useDispatch()
  const [modalOpen, setModalOpen] = useState(false)
  const [dateInvalid, setDateInvalid] = useState(false)
  const [agentSearchQuery, setAgentSearchQuery] = useState('')
  const { filters, loading, data } = useSelector((state) => state.callSearch)
  const { organizations } = useSelector((state) => state)
  const { organizationid: currentUserOrg, hierarchy_manager } = useSelector(
    (state) => state.currentUser
  )
  const { startDate, endDate, organizationId } = filters
  const { flattenedUserOrgHierarchy } = useSelector((state) => state.orgHierarchy)

  const organizationOptions = getOrganizationOptions(organizations)
  const hierarchyOrganizationOptions = getHierarchyOrganizationOptions(flattenedUserOrgHierarchy)
  const isBaltoAdmin = currentUserOrg === 1
  const showManagerHierarchyOrgDropdown = !isBaltoAdmin && hierarchy_manager
  const hasOrganizationDropdown = isBaltoAdmin || showManagerHierarchyOrgDropdown
  const validOrgId = organizationId !== '' && organizationId !== 0
  const callDurationInvalid =
    Boolean(filters.callDuration) &&
    Boolean(filters.maxCallDuration) &&
    Number(filters.callDuration) >= Number(filters.maxCallDuration)
  const dispositionsHidden =
    showManagerHierarchyOrgDropdown && organizationId !== currentUserOrg && validOrgId

  useEffect(() => {
    // Don't set default organization if Balto is pre-loading data from another org, because it will get set elsewhere
    if (!(isBaltoAdmin && params?.organizationId)) {
      dispatch(setFilters({ organizationId: currentUserOrg }))
    }
  }, [])

  useEffect(() => {
    if (validOrgId) {
      dispatch(fetchAgentsByOrg(organizationId))
      dispatch(fetchTagsByOrg(organizationId))
      dispatch(fetchPlaybooksByOrg(organizationId))

      if (!dispositionsHidden) {
        dispatch(fetchDispositionsByOrg(organizationId))
      }
    }
  }, [organizationId])

  useEffect(() => {
    if (filters.playbooks.length === 1) {
      dispatch(fetchPlaybookData(filters))
    } else {
      dispatch(setData({ playbookData: {} }))
      const initialPlaybookEventsFilters = {
        includes: true,
        selected: [],
      }
      dispatch(
        setFilters({
          checklist: initialPlaybookEventsFilters,
          notifications: initialPlaybookEventsFilters,
          deck: initialPlaybookEventsFilters,
          postcall: initialPlaybookEventsFilters,
          openCategories: initialPlaybookEventsFilters,
          openSubcategories: initialPlaybookEventsFilters,
        })
      )
    }
  }, [filters.playbooks])

  useEffect(() => {
    const initialOpenSubcategories = []
    const initialOpenDeckCategories =
      data.playbookData?.deck
        ?.filter((category) => {
          return category.items.some((item) => item.has_sub_items)
        })
        .map((category) => {
          const categoryContext = `deck - ${category.category}`

          category.items.forEach((item) => {
            if (!item.has_sub_items) {
              return
            }

            initialOpenSubcategories.push(`${categoryContext} - ${item.item}`)
          })

          return categoryContext
        }) || []
    const initialOpenPostcallCategories =
      data.playbookData?.postcall?.map((category) => `postcall - ${category.category}`) || []
    const initialOpenCategories = [...initialOpenDeckCategories, ...initialOpenPostcallCategories]

    dispatch(
      setFilters({
        openCategories: initialOpenCategories,
        openSubcategories: initialOpenSubcategories,
      })
    )
  }, [data.playbookData])

  const filterIsDisabled = () => {
    // Disable button if organization is not selected for admin, or hierarchy manager
    if (
      (isBaltoAdmin && !filters.organizationId) ||
      (showManagerHierarchyOrgDropdown && !filters.organizationId)
    ) {
      return 'Organization is required to apply filters'
    }

    // Disable button if date is over 62 days
    if (dateInvalid) {
      return 'Date range must be 60 days or less'
    }

    // Max call duration is less than min call duration
    if (callDurationInvalid) {
      return 'Min call duration must be less than max call duration'
    }

    return false
  }

  const handleOpenModal = () => {
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const handleClearRange = () => {
    const defaultStartDate = format(new Date(), "yyyy-MM-dd'T00:00:00'xxx")
    const defaultEndDate = format(new Date(), "yyyy-MM-dd'T23:59:59'xxx")

    dispatch(setDateRangeFilters({ startDate: defaultStartDate, endDate: defaultEndDate }))
    setDateInvalid(false)

    if (filters.playbooks.length === 1) {
      dispatch(
        fetchPlaybookData({ ...filters, startDate: defaultStartDate, endDate: defaultEndDate })
      )
    }

    dispatch(fetchCallsFromLatestFilters())
  }

  const handleUpdateRange = ({ selection }) => {
    const currentStartDate = format(selection.startDate, "yyyy-MM-dd'T00:00:00'xxx")
    const currentEndDate = format(selection.endDate, "yyyy-MM-dd'T23:59:59'xxx")

    const datesDiff = differenceInDays(selection.endDate, selection.startDate)

    if (datesDiff > 62) {
      setDateInvalid(true)
    } else {
      setDateInvalid(false)
      dispatch(setDateRangeFilters({ startDate: currentStartDate, endDate: currentEndDate }))

      if (filters.playbooks.length === 1) {
        dispatch(
          fetchPlaybookData({ ...filters, startDate: currentStartDate, endDate: currentEndDate })
        )
      }
    }
  }

  const handleUpdateFilters = (section, value) => {
    dispatch(setFilters({ [section]: value }))
  }

  const handleSubmitFilters = (event) => {
    event?.preventDefault()
    handleCloseModal()

    dispatch(fetchCalls({ filters, playbookData: data.playbookData }))
  }

  const handleClearFilters = () => {
    dispatch(clearAllFiltersAndFetchCalls())
  }

  const handleOrgSelect = (option) => {
    // Reset dependent filters on org change
    dispatch(setFilters({ agents: [], tags: [], playbooks: [], dispositions: [] }))

    handleUpdateFilters('organizationId', option.value)
  }

  const handleToggleEntry = (option) => {
    const { value: entryContext } = option
    const splitEntry = entryContext.split(' - ')
    const [section] = splitEntry
    const { selected: selectedFilters, includes } = filters[section]

    const isEntrySelected = selectedFilters.some((selected) => selected === entryContext)

    if (isEntrySelected) {
      // search lets us deselect all children when the parent is deselected
      const filtersToSelect = selectedFilters.filter((selected) => selected !== entryContext)
      dispatch(setFilters({ [section]: { includes, selected: filtersToSelect } }))
    } else {
      // if no response used, add the parent too
      const contextsToSelect = [entryContext]
      // if it's more than 3 it's a decklist entry
      if (section === 'deck' && splitEntry.length > 3) {
        const category = splitEntry[1]
        const entry = splitEntry[2]
        const parentContext = `${section} - ${category} - ${entry}`
        const isParentSelected = selectedFilters.some((selected) => selected === parentContext)

        if (!isParentSelected) {
          contextsToSelect.push(parentContext)
        }
      }
      dispatch(
        setFilters({ [section]: { includes, selected: [...selectedFilters, ...contextsToSelect] } })
      )
    }
  }

  const clearAllEntries = (section) => {
    dispatch(setFilters({ [section]: { includes: filters[section].includes, selected: [] } }))
  }

  const handleSaveAppliedCallExplorerFilters = (values) => {
    const callExplorerFilter = {
      name: values.name,
      isDefault: values.isDefault || false,
      dateRange: values.dateRange,
      ...filters,
    }

    dispatch(saveFilter(callExplorerFilter, CALL_EXPLORER_FILTER_TYPE))
  }

  const openCategory = (category) => {
    const { value, items } = category
    const subcategoriesToOpen = items
      .filter((item) => item.has_sub_items)
      .map((item) => `${value} - ${item.item}`)

    dispatch(
      setFilters({
        openCategories: [...filters.openCategories, value],
        openSubcategories: [...filters.openSubcategories, ...subcategoriesToOpen],
      })
    )
  }

  const closeCategory = (category) => {
    const { value } = category
    const updatedCategories = filters.openCategories.filter(
      (categoryContext) => categoryContext !== value
    )
    const updatedSubcategories = filters.openSubcategories.filter(
      (subCatContext) => !subCatContext.includes(value)
    )

    dispatch(
      setFilters({ openCategories: updatedCategories, openSubcategories: updatedSubcategories })
    )
  }

  const handleToggleCategory = (selectedCategory) => {
    if (!selectedCategory.expanded) {
      return closeCategory(selectedCategory)
    }
    return openCategory(selectedCategory)
  }

  const handleToggleIncludes = (section) => {
    dispatch(
      setFilters({
        [section]: { includes: !filters[section].includes, selected: filters[section].selected },
      })
    )
  }
  // we don't allow entry selection if more than one playbook selected. "All Playbooks" === [], should be disabled too
  const entrySelectionDisabled = filters.playbooks.length !== 1

  const checklistOptions = formatFlatOptions(
    'checklist',
    data.playbookData?.checklist,
    filters.checklist
  )
  const notificationOptions = formatFlatOptions(
    'notifications',
    data.playbookData?.notifications,
    filters.notifications
  )

  const deckOptions = formatNestedOptions('deck', data.playbookData?.deck, {
    ...filters.deck,
    openCategories: filters.openCategories,
    openSubcategories: filters.openSubcategories,
  })
  const postcallOptions = formatNestedOptions('postcall', data.playbookData?.postcall, {
    ...filters.postcall,
    openCategories: filters.openCategories,
    openSubcategories: filters.openSubcategories,
  })

  const filterDisabledReason = filterIsDisabled()

  const filterDisplayValues = getCallExplorerFilterDisplayValues(filters)
  const shouldDisplayValue = (accessor) =>
    callExplorerValueHasChanged(initialState, filters, accessor)
  const filterValuesApplied = Object.entries(filterDisplayValues).filter(([accessor]) =>
    shouldDisplayValue(accessor)
  )
  const filterDateRangeLabel = getDateRangeLabel(filters)
  const showSaveFiltersButton = !isEmpty(filterValuesApplied) || filterDateRangeLabel !== 'Today'

  return (
    <>
      <div className="flex-align-start flex-space-between small-gap mb full-width">
        <div className="flex-align-start small-gap flex-wrap">
          <CallSearchPills
            filterValuesApplied={filterValuesApplied}
            handleSubmitFilters={handleSubmitFilters}
            handleClearRange={handleClearRange}
            handleUpdateRange={handleUpdateRange}
            dateInvalid={dateInvalid}
            modalOpen={modalOpen}
          />
          <Button
            compact
            icon
            secondary
            onClick={handleOpenModal}
            className="svg-button"
            data-testid="call-search-filters-button"
          >
            <IconPlus />
            Filters
          </Button>
          {!childOrgSelected && showSaveFiltersButton && (
            <ConditionalTooltip content={filterDisabledReason} condition={!!filterDisabledReason}>
              <SaveCallFilter
                filterDateRangeLabel={filterDateRangeLabel}
                handleSave={handleSaveAppliedCallExplorerFilters}
                filterValuesApplied={filterValuesApplied}
              />
            </ConditionalTooltip>
          )}
        </div>

        <div className="flex-align-center small-gap">
          <Button
            compact
            secondary
            onClick={handleClearFilters}
            className="no-wrap"
            data-testid="call-search-reset-button"
          >
            Clear Filters
          </Button>
        </div>
      </div>

      {modalOpen && (
        <BasicModal
          data-testid="call-search-filters-modal"
          title="Call Search Filters"
          onClose={handleCloseModal}
          showCloseButton={false}
          closeOnDimmerClick={false}
          show={modalOpen}
          size="large"
        >
          <Form className="call-search-filters filter-form" onSubmit={handleSubmitFilters}>
            <div className="call-search-grid">
              {hasOrganizationDropdown && (
                <OrganizationFilter
                  dataTestId={
                    showManagerHierarchyOrgDropdown
                      ? 'hierarchy-organization-dropdown'
                      : 'organization-dropdown'
                  }
                  options={
                    showManagerHierarchyOrgDropdown
                      ? hierarchyOrganizationOptions
                      : organizationOptions
                  }
                  value={organizationId}
                  onChange={handleOrgSelect}
                  fixedWidth={false}
                  isClearable={false}
                />
              )}
              <Form.Field data-testid="date-picker">
                <label>Date Range</label>
                <DateRangePickerReusable
                  onChange={handleUpdateRange}
                  startDate={startDate}
                  endDate={endDate}
                  error={dateInvalid}
                />
                {dateInvalid && (
                  <ErrorMessage
                    content="Date range must be two months or less"
                    data-testid="datepicker-error"
                  />
                )}
              </Form.Field>
              <Form.Field data-testid="call-duration">
                <label>Min. Call Duration</label>
                <Select
                  allowFreeText
                  isNumberField
                  placeholder="Minutes per call"
                  options={minCallDurationOptions}
                  value={filters.callDuration}
                  onChange={(option, action) => {
                    action.action === 'clear'
                      ? handleUpdateFilters('callDuration', '')
                      : handleUpdateFilters('callDuration', option.value)
                  }}
                />
              </Form.Field>

              <Form.Field data-testid="max-call-duration">
                <label>Max Call Duration</label>
                <Input
                  type="number"
                  placeholder="Minutes per call"
                  value={filters.maxCallDuration}
                  onChange={(event) => {
                    if (event.target.value < 10000) {
                      handleUpdateFilters('maxCallDuration', event.target.value || '')
                    }
                  }}
                  error={callDurationInvalid}
                />
                {callDurationInvalid && (
                  <ErrorMessage
                    content="Max must be greater than min"
                    data-testid="callduration-error"
                  />
                )}
              </Form.Field>
              <Form.Field data-testid="agents-filter">
                <label>Agents</label>
                <MultiSelect
                  isSelectAll
                  placeholderPill="All Agents"
                  label="Agent"
                  options={!agentSearchQuery && data.agents.length > 1000 ? [] : data.agents}
                  optionsLength={data.agents.length}
                  loading={loading.agents}
                  value={filters.agents}
                  onInputChange={(inputValue) => {
                    setAgentSearchQuery(inputValue)
                  }}
                  onChange={(value) => {
                    handleUpdateFilters('agents', value)
                  }}
                  noOptionsMessage={() =>
                    agentSearchQuery ? 'No results found' : 'Begin typing to search...'
                  }
                />
              </Form.Field>
              <Form.Field data-testid="tags-filter">
                <label>Tags</label>
                <MultiSelect
                  placeholder="Select Tags"
                  options={data.tags}
                  loading={loading.tags}
                  value={filters.tags}
                  onChange={(value) => handleUpdateFilters('tags', value)}
                />
              </Form.Field>
              <WinsFilter
                filters={filters}
                options={[
                  { value: 'true', label: 'Only wins' },
                  { value: 'false', label: 'Only non-wins' },
                ]}
                onChange={(option, action) => {
                  handleUpdateFilters('isWin', action.action === 'clear' ? '' : option.value)
                }}
                fixedWidth={false}
              />
              <Form.Field data-testid="playbook-filter">
                <label>Playbooks</label>
                <MultiSelect
                  isSelectAll
                  placeholderPill="All Playbooks"
                  label="Playbook"
                  options={data.playbooks}
                  optionsLength={data.playbooks ? data.playbooks.length : 0}
                  loading={loading.playbooks}
                  value={filters.playbooks}
                  onChange={(value) => {
                    return handleUpdateFilters('playbooks', value)
                  }}
                />
              </Form.Field>
              <Form.Field data-testid="checklist-filter">
                <label>Checklist</label>
                <NestedSelect
                  disabled={entrySelectionDisabled}
                  options={checklistOptions}
                  value={filters.checklist.selected}
                  includes={filters.checklist.includes}
                  section="checklist"
                  clearAll={clearAllEntries}
                  toggleIncludes={handleToggleIncludes}
                  toggleEntry={handleToggleEntry}
                  toggleCategory={handleToggleCategory}
                />
              </Form.Field>
              <Form.Field data-testid="dynamic-prompt-filter">
                <label>Dynamic Prompts</label>
                <NestedSelect
                  disabled={entrySelectionDisabled}
                  options={deckOptions}
                  value={filters.deck.selected}
                  includes={filters.deck.includes}
                  openCategories={filters.openCategories}
                  openSubcategories={filters.openSubcategories}
                  section="deck"
                  clearAll={clearAllEntries}
                  toggleIncludes={handleToggleIncludes}
                  toggleEntry={handleToggleEntry}
                  toggleCategory={handleToggleCategory}
                />
              </Form.Field>
              <Form.Field data-testid="notification-filter">
                <label>Notifications</label>
                <NestedSelect
                  disabled={entrySelectionDisabled}
                  options={notificationOptions}
                  value={filters.notifications.selected}
                  includes={filters.notifications.includes}
                  section="notifications"
                  clearAll={clearAllEntries}
                  toggleIncludes={handleToggleIncludes}
                  toggleEntry={handleToggleEntry}
                  toggleCategory={handleToggleCategory}
                />
              </Form.Field>
              <Form.Field data-testid="postcall-filter">
                <label>Post Call</label>
                <NestedSelect
                  disabled={entrySelectionDisabled}
                  options={postcallOptions}
                  value={filters.postcall.selected}
                  includes={filters.postcall.includes}
                  openCategories={filters.openCategories}
                  openSubcategories={filters.openSubcategories}
                  section="postcall"
                  clearAll={clearAllEntries}
                  toggleIncludes={handleToggleIncludes}
                  toggleEntry={handleToggleEntry}
                  toggleCategory={handleToggleCategory}
                />
              </Form.Field>
              <Form.Field data-testid="audio-filter">
                <label>Calls Without Audio</label>
                <Select
                  isClearable={false}
                  placeholder="Include or exclude"
                  options={[
                    { value: true, label: 'Include' },
                    { value: false, label: 'Exclude' },
                  ]}
                  value={filters.includeCallsWithoutAudio}
                  onChange={(option) => {
                    handleUpdateFilters('includeCallsWithoutAudio', option.value)
                  }}
                />
              </Form.Field>
              <KeywordSearch
                isEnabled={flags?.callExplorerKeywordSearch}
                handleUpdateFilters={handleUpdateFilters}
                filters={filters}
              />
              {!isEmpty(data.dispositions) && !dispositionsHidden && (
                <Form.Field data-testid="dispositions-dropdown">
                  <label>Dispositions</label>
                  <MultiSelect
                    isSelectAll
                    placeholderPill="All Dispositions"
                    label="Dispositions"
                    options={data.dispositions}
                    optionsLength={data.dispositions ? data.dispositions.length : 0}
                    loading={loading.dispositions}
                    value={filters.dispositions}
                    onChange={(value) => {
                      return handleUpdateFilters('dispositions', value)
                    }}
                  />
                </Form.Field>
              )}
            </div>
            <div className="modal-footer">
              <div className="flex-align-center small-gap">
                <Button
                  secondary
                  type="button"
                  content="Clear Filters"
                  onClick={() => {
                    handleClearFilters()
                    setModalOpen(false)
                  }}
                />
                <ConditionalTooltip
                  content={filterDisabledReason}
                  condition={!!filterDisabledReason}
                >
                  <Button
                    primary
                    type="submit"
                    disabled={!!filterDisabledReason}
                    content="Apply Filters"
                    data-testid="submit-filters-button"
                  />
                </ConditionalTooltip>
              </div>
            </div>
          </Form>
        </BasicModal>
      )}
    </>
  )
}

export const CallSearchFilters = withLDConsumer()(CallSearchFiltersPage)
