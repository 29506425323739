import { toast } from 'react-toastify'
import { truncate, isEmpty, toNumber } from 'lodash'

import { apiService, fetchingAPI } from '@/api'
import { getTagsWithCategories } from '@/utils/helpers'
import { formatQACopilotFilters } from '@/reducers/qa-copilot/qa-copilot.helpers'

import { fetchPrompts } from '@/reducers/prompts/prompts.actions'
import { setData, setError, setFilter, setLoading, setScorecardConfig } from './qa-copilot.redux'

// Filters
export const fetchAgentsByOrg = (organizationId) => async (dispatch) => {
  dispatch(setLoading({ agents: true }))

  try {
    const { users } = await fetchingAPI(
      `${apiService.web}/api/organizations/${organizationId}/users`,
      'GET',
      dispatch
    )

    const agentOptions = users
      .sort((a, b) => a.last_name.localeCompare(b.last_name))
      .map((agent) => ({
        value: agent.id,
        label: `${agent.first_name} ${agent.last_name}`,
      }))

    dispatch(setData({ agents: agentOptions }))
  } catch (err) {
    toast.error('Failed to fetch agents')
  } finally {
    dispatch(setLoading({ agents: false }))
  }
}

export const fetchPlaybooksByOrg = (organizationId) => async (dispatch) => {
  dispatch(setLoading({ playbooks: true }))

  try {
    const playbooks = await fetchingAPI(
      `${apiService.web}/api/${organizationId}/configs?requested_properties=name,cid,organization_id,organization_name`,
      'GET',
      dispatch
    )

    const playbookOptions = playbooks
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((playbook) => ({
        value: playbook.cid,
        label: playbook.name,
      }))

    dispatch(setData({ playbooks: playbookOptions }))
  } catch (err) {
    toast.error('Failed to fetch playbooks')
  } finally {
    dispatch(setLoading({ playbooks: false }))
  }
}

export const fetchTagsByOrg = (organizationId) => async (dispatch) => {
  dispatch(setLoading({ tags: true }))

  try {
    const [tags, tagCategories] = await Promise.all([
      fetchingAPI(`${apiService.web}/api/organizations/${organizationId}/tags`, 'GET', dispatch),
      fetchingAPI(
        `${apiService.web}/api/organizations/${organizationId}/tags/categories`,
        'GET',
        dispatch
      ),
    ])

    const tagsOptionsWithCategories = getTagsWithCategories(tags, tagCategories)

    dispatch(setData({ tags: tagsOptionsWithCategories }))
  } catch (err) {
    toast.error('Failed to fetch tags')
  } finally {
    dispatch(setLoading({ tags: false }))
  }
}

export const fetchDispositionsByOrg = (organizationId) => async (dispatch) => {
  dispatch(setLoading({ dispositions: true }))

  try {
    const dispositions = await fetchingAPI(
      `${apiService.reporting}/api/dispositions?organization_id=${organizationId}`,
      'GET',
      dispatch
    )
    const dispoOptions = dispositions
      .map(({ name }) => ({ value: name, label: name }))
      .sort((a, b) => a.label.localeCompare(b.label))
    dispatch(setData({ dispositions: dispoOptions }))
  } catch (err) {
    toast.error('Failed to fetch dispositions')
  } finally {
    dispatch(setLoading({ dispositions: false }))
  }
}

export const fetchCopilotScorecardsByOrg =
  (organizationId, isEnterprise, scorecardType = 'copilot') =>
  async (dispatch) => {
    dispatch(setLoading({ scorecards: true }))

    try {
      const scorecards = await fetchingAPI(
        `${apiService.scorecard}/scoring/scorecards/current?requested_organization_id=${organizationId}&scorecard_type=${scorecardType}${isEnterprise ? '&hierarchy=True' : ''}`,
        'GET',
        dispatch
      )
      const scorecardOptions = scorecards
        .map((scorecard) => ({ value: scorecard.sid, label: scorecard.name }))
        .sort((a, b) => a.label.localeCompare(b.label))

      dispatch(setData({ scorecards: scorecardOptions }))
    } catch (err) {
      toast.error('Failed to fetch scorecards')
    } finally {
      dispatch(setLoading({ scorecards: false }))
    }
  }

// Scorecard CRUD
export const fetchScorecardConfigById = (scorecardConfigId, isBaltoAdmin) => async (dispatch) => {
  dispatch(setLoading({ scorecardConfig: true }))

  try {
    const scorecardConfig = await fetchingAPI(
      `${apiService.scorecard}/scoring/scorecards/scorecard/${scorecardConfigId}`,
      'GET',
      dispatch
    )
    dispatch(setScorecardConfig(scorecardConfig))
    if (isBaltoAdmin) {
      const promptIds = scorecardConfig.sections
        .flatMap((section) => section.measures)
        .flatMap((measure) => measure.criteria)
        .map((criteria) => criteria.prompt_id)
        .filter((promptId) => promptId !== null)
      if (scorecardConfig.eligibility_prompt_id !== null) {
        promptIds.push(scorecardConfig.eligibility_prompt_id)
      }
      if (promptIds) {
        dispatch(fetchPrompts(promptIds))
      }
    }
  } catch (err) {
    toast.error('Failed to fetch scorecard')
    dispatch(setError({ scorecardConfig: true }))
  } finally {
    dispatch(setLoading({ scorecardConfig: false }))
  }
}

export const createScorecardConfig = (scorecardConfig, history, prompts) => async (dispatch) => {
  dispatch(setLoading({ scorecardConfig: true }))
  const createWithScid = async (prompt) => {
    const promptWithOrg = { ...prompt, organization_id: scorecardConfig.organization_id }
    if (promptWithOrg.scid) {
      const body = JSON.stringify(promptWithOrg)
      const response = await fetchingAPI(`${apiService.summary}/prompts`, 'POST', dispatch, body)
      return { ...response, scid: promptWithOrg.scid }
    }
    return promptWithOrg
  }

  try {
    const scorecardConfigWithPrompts = await Promise.all(
      prompts.map((prompt) => createWithScid(prompt))
    ).then((updatedPrompts) => {
      return {
        ...scorecardConfig,
        eligibility_prompt_id:
          updatedPrompts.find((prompt) => prompt.scid === 'eligibility')?.id ||
          scorecardConfig.eligibility_prompt_id,
        sections: scorecardConfig.sections.map((section) => ({
          ...section,
          measures: section.measures.map((measure) => ({
            ...measure,
            criteria: measure.criteria.map((criteria) => ({
              ...criteria,
              prompt_id:
                updatedPrompts.find((prompt) => prompt.scid === criteria.scid)?.id ||
                criteria.prompt_id,
            })),
          })),
        })),
      }
    })
    const body = JSON.stringify(scorecardConfigWithPrompts)
    const newScorecardConfig = await fetchingAPI(
      `${apiService.scorecard}/scoring/scorecards/scorecard/`,
      'POST',
      dispatch,
      body
    )
    const nextUrl = scorecardConfig.type === 'copilot' ? '/qa-copilot' : '/coaching/scorecards'
    history.push(`${nextUrl}/${newScorecardConfig.id}`)
    toast.success('Scorecard created!')
  } catch (err) {
    toast.error('Failed to create scorecard')
  } finally {
    dispatch(setLoading({ scorecardConfig: false }))
  }
}

export const updateScorecardConfig = (scorecardConfig, history, prompts) => async (dispatch) => {
  dispatch(setLoading({ scorecardConfig: true }))
  const createWithScid = async (prompt) => {
    const promptWithOrg = { ...prompt, organization_id: scorecardConfig.organization_id }
    if (promptWithOrg.scid) {
      const body = JSON.stringify(promptWithOrg)
      const response = await fetchingAPI(`${apiService.summary}/prompts`, 'POST', dispatch, body)
      return { ...response, scid: promptWithOrg.scid }
    }
    return promptWithOrg
  }

  try {
    // update scorecard config criteria with new prompt ids if we can link by scid, otherwise there is no update
    const scorecardConfigWithPrompts = await Promise.all(
      prompts.map((prompt) => createWithScid(prompt))
    ).then((updatedPrompts) => {
      return {
        ...scorecardConfig,
        eligibility_prompt_id:
          updatedPrompts.find((prompt) => prompt.scid === 'eligibility')?.id ||
          scorecardConfig.eligibility_prompt_id,
        sections: scorecardConfig.sections.map((section) => ({
          ...section,
          measures: section.measures.map((measure) => ({
            ...measure,
            criteria: measure.criteria.map((criteria) => ({
              ...criteria,
              prompt_id:
                updatedPrompts.find((prompt) => prompt.scid === criteria.scid)?.id ||
                criteria.prompt_id,
            })),
          })),
        })),
      }
    })
    const body = JSON.stringify(scorecardConfigWithPrompts)
    const updatedScorecardConfig = await fetchingAPI(
      `${apiService.scorecard}/scoring/scorecards/scorecard/${scorecardConfig.id}`,
      'PUT',
      dispatch,
      body
    )
    const nextUrl = scorecardConfig.type === 'copilot' ? '/qa-copilot' : '/coaching/scorecards'
    history.push(`${nextUrl}/${updatedScorecardConfig.id}`)
    toast.success('Scorecard updated!')
  } catch (err) {
    if (err?.status === 409) {
      toast.error(
        'Another user saved a new version while you were editing. Please note your changes, navigate to the newest version, and try again.'
      )
    } else {
      toast.error('Failed to update scorecard')
    }
  } finally {
    dispatch(setLoading({ scorecardConfig: false }))
  }
}

export const deleteScorecardConfig = (scorecardConfig, history) => async (dispatch) => {
  try {
    await fetchingAPI(
      `${apiService.scorecard}/scoring/scorecards/scorecard/${scorecardConfig.id}`,
      'DELETE',
      dispatch
    )
    toast.success('Scorecard deleted successfully')
    const nextUrl = scorecardConfig.type === 'copilot' ? '/qa-copilot' : '/coaching/scorecards'
    history.push(nextUrl)
  } catch (err) {
    toast.error('Failed to delete scorecard')
  }
}

export const toggleActivateScorecardConfig = (scorecardConfig) => async (dispatch) => {
  dispatch(setLoading({ scorecardConfig: true }))

  try {
    const updatedScorecardConfig = await fetchingAPI(
      `${apiService.scorecard}/scoring/scorecards/scorecard/${scorecardConfig.sid}/settings?toggle_active=True`,
      'PATCH',
      dispatch
    )

    dispatch(setScorecardConfig(updatedScorecardConfig))
    toast.success(`Scorecard ${scorecardConfig.active ? 'deactivated' : 'activated'} successfully`)
  } catch (err) {
    toast.error(`Failed to ${scorecardConfig.active ? 'deactivate' : 'activate'} scorecard`)
  } finally {
    dispatch(setLoading({ scorecardConfig: false }))
  }
}

export const getCopilotTestResult = (testPrompt) => async (dispatch) => {
  dispatch(setLoading({ copilotTestPrompt: true }))

  try {
    const body = JSON.stringify(testPrompt)
    const response = await fetchingAPI(`${apiService.summary}/copilot/test`, 'POST', dispatch, body)

    return response
  } catch (err) {
    return toast.error('Failed to test copilot prompt')
  } finally {
    dispatch(setLoading({ copilotTestPrompt: false }))
  }
}

export const sendEmailToAssignee = (data) => async (dispatch) => {
  try {
    const body = JSON.stringify(data)
    await fetchingAPI(`${apiService.scorecard}/coaching/assign`, 'POST', dispatch, body)
    toast.success('Assigned coaching opportunity')
  } catch (err) {
    toast.error('Failed to assign coaching opportunity')
  }
}

// ==========
// Dashboards
// ==========
export const getCallsScored = (params) => async (dispatch) => {
  dispatch(setLoading({ evaluatedCallData: true }))

  try {
    const evaluatedCallData = await fetchingAPI(
      `${apiService.scorecard}/scoring/scores/aggregate/callCount?${params}`
    )

    dispatch(setData({ evaluatedCallData }))
  } catch (err) {
    toast.error('Failed to get calls scored')
  } finally {
    dispatch(setLoading({ evaluatedCallData: false }))
  }
}
export const getFailedCallCount = (params) => async (dispatch) => {
  dispatch(setLoading({ failedCallData: true }))

  try {
    const failedCallData = await fetchingAPI(
      `${apiService.scorecard}/scoring/scores/aggregate/callCount?${params}&threshold=improvement`,
      'GET',
      dispatch
    )
    dispatch(setData({ failedCallData }))
  } catch (err) {
    toast.error('Failed to get failed call count')
  } finally {
    dispatch(setLoading({ failedCallData: false }))
  }
}

export const getNonCompliantCriteria = (params) => async (dispatch) => {
  dispatch(setLoading({ nonCompliantCriteria: true }))

  try {
    const nonCompliantCriteria = await fetchingAPI(
      `${apiService.scorecard}/scoring/scores/aggregate/callCount/by_criteria?${params}&threshold=improvement`,
      'GET',
      dispatch
    )
    const formattedNonCompliantCriteria = nonCompliantCriteria
      .sort((a, b) => b.call_count - a.call_count)
      .filter((criteria, i) => i < 7)
      .map((criteria) => ({ ...criteria, name: truncate(criteria.criteria_name, { length: 20 }) }))

    dispatch(setData({ nonCompliantCriteria: formattedNonCompliantCriteria }))
  } catch (err) {
    toast.error('Failed to get non-compliant criteria')
  } finally {
    dispatch(setLoading({ nonCompliantCriteria: false }))
  }
}

export const getNonCompliantUsers = (params) => async (dispatch) => {
  dispatch(setLoading({ nonCompliantAgents: true }))

  try {
    const nonCompliantAgents = await fetchingAPI(
      `${apiService.scorecard}/scoring/scores/aggregate/callCount/by_user?${params}&threshold=improvement`,
      'GET',
      dispatch
    )

    const formattedNonCompliantAgents = nonCompliantAgents
      .sort((a, b) => b.call_count - a.call_count)
      .filter((agent, i) => i < 7)
      .map((agent) => ({
        ...agent,
        name: truncate(`${agent.first_name} ${agent.last_name}`, { length: 20 }),
      }))

    dispatch(setData({ nonCompliantAgents: formattedNonCompliantAgents }))
  } catch (err) {
    toast.error('Failed to get non-compliant agents')
  } finally {
    dispatch(setLoading({ nonCompliantAgents: false }))
  }
}

export const fetchAggregateScoresByOrgIdMultipleScorecardsByUser = (params) => async (dispatch) => {
  dispatch(setLoading({ reportingByUser: true }))

  try {
    const scoreData = await fetchingAPI(
      `${apiService.scorecard}/scoring/scores/calls/scored_calls_by_user?${params}&threshold=improvement`,
      'GET',
      dispatch
    )
    dispatch(setData({ reportingByUser: { aggregate_scores_by_user: scoreData } }))
  } catch (err) {
    dispatch(setFilter('reportingByUser', {}))
    toast.error('Failed to fetch scores')
  } finally {
    dispatch(setLoading({ reportingByUser: false }))
  }
}

export const fetchAggregateScoresByOrgIdMultipleScorecards = (params) => async (dispatch) => {
  dispatch(setLoading({ reporting: true }))

  try {
    const scoreData = await fetchingAPI(
      `${apiService.scorecard}/scoring/scores/aggregate/multiple?${params}`,
      'GET',
      dispatch
    )
    dispatch(setData({ reporting: scoreData }))
  } catch (err) {
    toast.error('Failed to fetch scores')
  } finally {
    dispatch(setLoading({ reporting: false }))
  }
}

export const fetchAggregateScoresByOrgIdMultipleScorecardsByDay = (params) => async (dispatch) => {
  dispatch(setLoading({ reportingByDay: true }))

  try {
    const scoreData = await fetchingAPI(
      `${apiService.scorecard}/scoring/scores/aggregate/multiple/by_date?${params}`,
      'GET',
      dispatch
    )
    dispatch(setData({ reportingByDay: scoreData }))
  } catch (err) {
    toast.error('Failed to fetch scores')
  } finally {
    dispatch(setLoading({ reportingByDay: false }))
  }
}

export const fetchCoachingOpportunities = (params) => async (dispatch) => {
  dispatch(setLoading({ coachingOpportunities: true }))

  try {
    const response = await fetchingAPI(
      `${apiService.scorecard}/scoring/scores/aggregate/coachingOpportunities/by_user?${params}`,
      'GET',
      dispatch
    )
    dispatch(setData({ coachingOpportunities: response }))
  } catch (err) {
    toast.error('Failed to fetch coaching opportunities')
  } finally {
    dispatch(setLoading({ coachingOpportunities: false }))
  }
}

export const fetchTopInfractors = (params) => async (dispatch) => {
  dispatch(setLoading({ topInfractors: true }))

  try {
    const response = await fetchingAPI(
      `${apiService.scorecard}/scoring/scores/aggregate/callCount/by_criteria?${params}`,
      'GET',
      dispatch
    )
    dispatch(setData({ topInfractors: response }))
  } catch (err) {
    toast.error('Failed to fetch non-compliant agents')
  } finally {
    dispatch(setLoading({ topInfractors: false }))
  }
}

export const fetchCopilotDashboardData =
  (organizationId, filtersFromURLParams = {}) =>
  async (dispatch, getState) => {
    const { filters } = getState().qaCopilot
    const updatedFilters = { ...filters, ...filtersFromURLParams }
    const params = formatQACopilotFilters(updatedFilters, organizationId)

    dispatch(getCallsScored(params))
    dispatch(getFailedCallCount(params))
    dispatch(getNonCompliantCriteria(params))
    dispatch(getNonCompliantUsers(params))
    dispatch(fetchAggregateScoresByOrgIdMultipleScorecardsByUser(params))
    dispatch(fetchAggregateScoresByOrgIdMultipleScorecards(params))
    dispatch(fetchAggregateScoresByOrgIdMultipleScorecardsByDay(params))
    dispatch(fetchCoachingOpportunities(params))
    dispatch(fetchTopInfractors(params))
  }

export const fetchCopilotFilterData = (organizationId, dispositionsHidden) => async (dispatch) => {
  await Promise.all([
    dispatch(fetchCopilotScorecardsByOrg(organizationId)),
    dispatch(fetchAgentsByOrg(organizationId)),
    dispatch(fetchTagsByOrg(organizationId)),
  ])

  if (!dispositionsHidden) {
    await dispatch(fetchDispositionsByOrg(organizationId))
  }
}

export const fetchInitialCopilotDashboardData =
  (hasOrganizationDropdown, currentUserOrgId, params, dispositionsHidden) =>
  async (dispatch, getState) => {
    const organizationIdFromUrlParams = params?.organization_id
      ? toNumber(params.organization_id)
      : null

    // Get all the things for users that cannot see organizations
    if (!hasOrganizationDropdown) {
      await dispatch(fetchCopilotFilterData(currentUserOrgId, false))
      dispatch(fetchCopilotDashboardData(currentUserOrgId))
    } else {
      // For Balto Admin and Hierarchy Manager, only do the initial fetch if there are params in the URL
      // eslint-disable-next-line no-lonely-if
      if (!isEmpty(params)) {
        await dispatch(
          fetchCopilotFilterData(
            organizationIdFromUrlParams,
            dispositionsHidden(organizationIdFromUrlParams)
          )
        )

        const updatedFiltersFromParams = {
          ...(params.start_date && { startDate: params.start_date }),
          ...(params.end_date && { endDate: params.end_date }),
          ...(params.organization_id && { organizationId: organizationIdFromUrlParams }),
          ...(params.scorecard_config_sid && {
            scorecards: getState().qaCopilot.data.scorecards.filter(
              (scorecard) => scorecard.value === params.scorecard_config_sid
            ),
          }),
        }

        dispatch(setFilter(updatedFiltersFromParams))
        dispatch(fetchCopilotDashboardData(currentUserOrgId, updatedFiltersFromParams))
      }
    }
  }

// =====================
// Enterprise Dashboards
// =====================
export const fetchEnterpriseEvaluatedCalls = (params) => async (dispatch) => {
  dispatch(setLoading({ enterpriseEvaluatedCallData: true }))

  try {
    const response = await fetchingAPI(
      `${apiService.scorecard}/enterprise/aggregate/callCount?${params}`,
      'GET',
      dispatch
    )

    const callIds = response.organizations.flatMap((organization) => organization.call_ids)
    const callData = { call_ids: callIds, call_count: response.total_calls }
    dispatch(setData({ enterpriseEvaluatedCallData: callData }))
  } catch (err) {
    toast.error('Failed to fetch evaluated calls')
  } finally {
    dispatch(setLoading({ enterpriseEvaluatedCallData: false }))
  }
}

export const fetchEnterpriseAverageScore = (params) => async (dispatch) => {
  dispatch(setLoading({ enterpriseAverageScore: true }))

  try {
    const response = await fetchingAPI(
      `${apiService.scorecard}/enterprise/aggregate/multiple?${params}`,
      'GET',
      dispatch
    )
    dispatch(setData({ enterpriseAverageScore: response.aggregate_score }))
  } catch (err) {
    toast.error('Failed to fetch average score')
  } finally {
    dispatch(setLoading({ enterpriseAverageScore: false }))
  }
}

export const fetchEnterpriseFailedCalls = (params) => async (dispatch) => {
  dispatch(setLoading({ enterpriseFailedCallData: true }))

  try {
    const response = await fetchingAPI(
      `${apiService.scorecard}/enterprise/aggregate/callCount?${params}&threshold=improvement`,
      'GET',
      dispatch
    )

    const callIds = response.organizations.flatMap((organization) => organization.call_ids)
    const callData = { call_ids: callIds, call_count: response.total_calls }
    dispatch(setData({ enterpriseFailedCallData: callData }))
  } catch (err) {
    toast.error('Failed to fetch failed calls')
  } finally {
    dispatch(setLoading({ enterpriseFailedCallData: false }))
  }
}

export const fetchEnterpriseScorecardComplianceScores = (params) => async (dispatch) => {
  dispatch(setLoading({ enterpriseScorecardPerformance: true }))

  try {
    const { organizations } = await fetchingAPI(
      `${apiService.scorecard}/enterprise/aggregate/multiple?${params}`,
      'GET',
      dispatch
    )
    dispatch(
      setData({
        enterpriseScorecardPerformance: organizations
          .flatMap(({ organization_name, organization_id, scorecard_configs }) =>
            scorecard_configs.map((config) => ({ ...config, organization_id, organization_name }))
          )
          .map((scorecard) => ({
            ...scorecard,
            organization_id: scorecard.organization_id,
            organization_name: scorecard.organization_name,
          })),
      })
    )
  } catch (err) {
    toast.error('Failed to fetch scorecard performance')
  } finally {
    dispatch(setLoading({ enterpriseScorecardPerformance: false }))
  }
}

export const fetchEnterpriseOrganizationPerformance = (params) => async (dispatch) => {
  dispatch(setLoading({ enterpriseOrganizationPerformance: true }))

  try {
    const [{ organizations }, totalCalls, failingCalls] = await Promise.all([
      fetchingAPI(
        `${apiService.scorecard}/enterprise/aggregate/multiple?${params}`,
        'GET',
        dispatch
      ),
      fetchingAPI(
        `${apiService.scorecard}/enterprise/aggregate/callCount?${params}`,
        'GET',
        dispatch
      ),
      fetchingAPI(
        `${apiService.scorecard}/enterprise/aggregate/callCount?${params}&threshold=improvement`,
        'GET',
        dispatch
      ),
    ])
    const organizationsPerformance = {}
    failingCalls.organizations.forEach(({ organization_name, organization_id, call_ids }) => {
      organizationsPerformance[organization_id] = {
        organization_name,
        organization_id,
        failing_call_ids: call_ids,
      }
    })
    totalCalls.organizations.forEach(({ organization_id, organization_name, call_ids }) => {
      if (!organizationsPerformance[organization_id]) {
        organizationsPerformance[organization_id] = {
          organization_name,
          organization_id,
          failing_call_ids: [],
        }
      } else {
        organizationsPerformance[organization_id].passing_call_ids = call_ids.filter(
          (callId) => !organizationsPerformance[organization_id].failing_call_ids.includes(callId)
        )
      }
    })
    organizations.forEach(({ organization_id, aggregate_score }) => {
      organizationsPerformance[organization_id].aggregate_score = aggregate_score
    })
    dispatch(setData({ enterpriseOrganizationPerformance: organizationsPerformance }))
  } catch (err) {
    toast.error('Failed to fetch organization performance')
  } finally {
    dispatch(setLoading({ enterpriseOrganizationPerformance: false }))
  }
}

export const fetchEnterpriseScoresPerOrganization = (params) => async (dispatch) => {
  dispatch(setLoading({ enterpriseScoresPerOrganization: true }))

  try {
    const response = await fetchingAPI(
      `${apiService.scorecard}/enterprise/aggregate/multiple/by_date?${params}`,
      'GET',
      dispatch
    )
    dispatch(setData({ enterpriseScoresPerOrganization: response }))
  } catch (err) {
    toast.error('Failed to fetch organization performance')
  } finally {
    dispatch(setLoading({ enterpriseScoresPerOrganization: false }))
  }
}

export const fetchEnterpriseDashboardData = (organizationId) => async (dispatch, getState) => {
  const { filters } = getState().qaCopilot
  const params = formatQACopilotFilters(filters, organizationId)

  dispatch(fetchEnterpriseEvaluatedCalls(params))
  dispatch(fetchEnterpriseAverageScore(params))
  dispatch(fetchEnterpriseFailedCalls(params))
  dispatch(fetchEnterpriseScorecardComplianceScores(params))
  dispatch(fetchEnterpriseOrganizationPerformance(params))
  dispatch(fetchEnterpriseScoresPerOrganization(params))
}
