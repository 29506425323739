import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'

import {
  removeFilterAndFetchCalls,
  clearAllFiltersAndFetchCalls,
} from '@/reducers/callSearch/callSearch.actions'
import { DateRangePickerReusable } from '@/components/datePickers/DateRangePickerReusable'
import { getOrganizationOptions, getHierarchyOrganizationOptions } from '@/utils/helpers'
import { FilterPill } from '@/components/filters/FilterPill'
import { FilterTypeSelect } from '@/components/filters/types/FilterTypeSelect'

export const CallSearchPills = ({
  filterValuesApplied,
  handleSubmitFilters,
  handleClearRange,
  handleUpdateRange,
  dateInvalid,
  modalOpen,
}) => {
  const dispatch = useDispatch()
  const { organizations } = useSelector((state) => state)
  const { filters, loading } = useSelector((state) => state.callSearch)
  const { organizationid: currentUserOrgId, hierarchy_manager: isHierarchyManager } = useSelector(
    (state) => state.currentUser
  )
  const isBaltoAdmin = currentUserOrgId === 1
  const showManagerHierarchyOrgDropdown = !isBaltoAdmin && isHierarchyManager
  const { flattenedUserOrgHierarchy } = useSelector((state) => state.orgHierarchy)
  const hasOrganizationDropdown = isBaltoAdmin || showManagerHierarchyOrgDropdown
  const organizationOptions = getOrganizationOptions(organizations)
  const hierarchyOrganizationOptions = getHierarchyOrganizationOptions(flattenedUserOrgHierarchy)

  const handleRemoveFilter = (accessor) => {
    dispatch(removeFilterAndFetchCalls(accessor))
  }

  return (
    <>
      {hasOrganizationDropdown && (
        <FilterTypeSelect
          isClearable={filters.organizationId !== currentUserOrgId}
          loading={loading?.calls}
          schema={{
            accessor: 'organizationId',
            label: 'Organization',
            options: showManagerHierarchyOrgDropdown
              ? hierarchyOrganizationOptions
              : organizationOptions,
          }}
          value={filters.organizationId}
          updateSearch={(accessor, selectedOrgId) => {
            if (!selectedOrgId) {
              dispatch(clearAllFiltersAndFetchCalls())
            }
            dispatch(clearAllFiltersAndFetchCalls(selectedOrgId))
          }}
        />
      )}

      <DateRangePickerReusable
        withButtons
        startDate={filters.startDate}
        endDate={filters.endDate}
        cancelLabel="Clear"
        onCancel={handleClearRange}
        onChange={handleUpdateRange}
        onSave={handleSubmitFilters}
        shouldNotResetOnCancel
        saveLabel="Apply"
        error={dateInvalid}
        disableSaveButton={dateInvalid}
        isFilterPill
        friendlyLabel
        basicLabel
        modalOpen={modalOpen}
      />
      {!isEmpty(filterValuesApplied) && (
        <>
          {filterValuesApplied.map(([accessor, properties]) => (
            <FilterPill
              key={accessor}
              accessor={accessor}
              handleRemoveFilter={() => handleRemoveFilter(accessor)}
              loading={loading?.calls}
              {...properties}
            />
          ))}
        </>
      )}
    </>
  )
}
