import React from 'react'
import { withRouter } from 'react-router-dom'
import { IconCheck } from '@tabler/icons-react'
import { useSelector } from 'react-redux'

const Receipt = () => {
  const { uploadResponse } = useSelector((state) => state.organizationVoipCampaignsCSV)

  const newMappings = uploadResponse.additions || {}
  const updateMappings = uploadResponse.updates || {}
  const deleteMappings = uploadResponse.deletions || []

  return (
    <>
      <div>
        <span className="flex-align-center small-gap">
          <label style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
            UPLOAD COMPLETE{' '}
          </label>
        </span>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: 10,
          }}
        >
          {Object.keys(newMappings).length > 0 && (
            <span className="flex-align-center small-gap">
              <IconCheck className="icon-svg status-success" />
              <label style={{ fontWeight: 'bold' }}>New mappings created: </label>
              <p>{Object.keys(newMappings).length}</p>
            </span>
          )}
          {Object.keys(updateMappings).length > 0 && (
            <span className="flex-align-center small-gap">
              <IconCheck className="icon-svg status-success" />
              <label style={{ fontWeight: 'bold' }}>Mappings updated: </label>
              <p>{Object.keys(updateMappings).length}</p>
            </span>
          )}
          {deleteMappings.length > 0 && (
            <span className="flex-align-center small-gap">
              <IconCheck className="icon-svg status-success" />
              <label style={{ fontWeight: 'bold' }}>Mappings deleted: </label>
              <p>{deleteMappings.length}</p>
            </span>
          )}
        </div>
      </div>
    </>
  )
}

export default withRouter(Receipt)
