import React from 'react'
import { IconCalendar, IconCircleDot, IconSparkles, IconTag, IconUser } from '@tabler/icons-react'
import { Segment, Button, Divider } from 'semantic-ui-react'

import { Pill } from '@/components/pills/Pill'
import { AudioPlayer } from '@/components/audio/AudioPlayer'

import './CoachingDrawer.scss'

export const CoachingDrawer = () => {
  return (
    <div className="coaching-drawer">
      <h2>Ineffective Objection Handling</h2>

      <table className="key-value-table">
        <thead>
          <tr>
            <td>
              <div className="flex-align-center small-gap">
                <IconCircleDot className="icon-svg" />
                <strong>Status</strong>
              </div>
            </td>
            <td>Not Reviewed</td>
          </tr>
          <tr>
            <td>
              <div className="flex-align-center small-gap">
                <IconUser className="icon-svg" />
                <strong>Agent</strong>
              </div>
            </td>
            <td>Barbara Coa</td>
          </tr>
          <tr>
            <td>
              <div className="flex-align-center small-gap">
                <IconTag className="icon-svg" />
                <strong>Type</strong>
              </div>
            </td>
            <td>
              <Pill small emphasized bold className="uppercase">
                Manual
              </Pill>
            </td>
          </tr>
          <tr>
            <td>
              <div className="flex-align-center small-gap">
                <IconCalendar className="icon-svg" />
                <strong>Date Created</strong>
              </div>
            </td>
            <td>Today</td>
          </tr>
        </thead>
      </table>

      <Divider />

      <h3 className="status-magical flex-align-center small-gap">
        <IconSparkles />
        Coaching Opportunity
      </h3>

      <p>
        {`The customer expressed concerns about the product's cost, saying, 'It's too expensive,' but
        the agent did not offer value-based responses. Training the agent on addressing price
        objections could improve their ability to close sales.`}
      </p>

      <h4>Audio Clip</h4>

      <Segment className="not-padded">
        <AudioPlayer hidePlaybackRate />
      </Segment>

      <h3 className="status-magical flex-align-center small-gap">
        <IconSparkles />
        Suggested Action Items
      </h3>

      <h5>Enhance Training on Price Objections:</h5>

      <p>
        Develop a targeted training module to help the agent effectively respond to customer
        concerns about product cost with value-based explanations.
      </p>

      <div className="drawer-footer">
        <div className="flex-align-center flex-space-between medium-gap">
          <div className="flex-align-center medium-gap">
            <Button basic>Ignore</Button>
          </div>
          <div className="flex-align-center medium-gap">
            <Button secondary>Mark as Reviewed</Button>
            <Button primary>Save Notes and Review</Button>
          </div>
        </div>
      </div>
    </div>
  )
}
