import React, { forwardRef, useEffect, useState } from 'react'
import classNames from 'classnames'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { capitalize } from 'lodash'
import { IconArrowUpRight, IconMessageReport, IconSparkles } from '@tabler/icons-react'
import { Field, useFormikContext } from 'formik'
import { Button, Divider, Dropdown as SemanticDropdown, TextArea } from 'semantic-ui-react'

import { Pill } from '@/components/pills/Pill'
import { Dropdown } from '@/components/forms/formik'
import { resolveDispute, setHardSelectedEvent } from '@/reducers/callSearch/callSearch.actions'

import { Banner } from '@/components/banners/Banner'
import { setCallExplorerData } from '@/reducers/callSearch/callSearch.redux'

import {
  CALL_EXPLORER_CRITERIA_FILTER_ENUMS,
  formatTime,
  updateCallExplorerParams,
} from './helpers'

export const QAScoreEditCriteria = forwardRef(
  ({ criteriaScore, score, callId, active, index, formikProps }, ref) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const history = useHistory()
    const { agent_name: agentName } = useSelector((state) => state.callSearch.callExplorer.metadata)
    const { selectedCriteriaFilter } = useSelector((state) => state.callSearch.callExplorer)
    const { user_id: userId } = useSelector((state) => state.currentUser)
    const [showDisputeButtons, setShowDisputeButtons] = useState(false)
    const [criteriaLoading, setCriteriaLoading] = useState(false)
    const disputeStatus = criteriaScore?.dispute?.status
    const [showNote, setShowNote] = useState(false)

    useEffect(() => {
      if (criteriaScore?.dispute?.status === 'pending') {
        setShowDisputeButtons(true)
      }

      const criteriaNote = criteriaScore?.notes?.find((note) => note.created_by === userId)

      if (criteriaNote) {
        formikProps.setFieldValue(`${criteriaScore.uuid}-note`, criteriaNote.note_text)
        setShowNote(true)
      }
    }, [])

    const getStatusForScore = (score) => {
      if (score === 1) {
        return 'Passed'
      }
      if (score === 0) {
        return 'Failed'
      }
      if (score === -1) {
        return 'Skipped'
      }
      return ''
    }

    const handleScoreChange = async (newScore) => {
      await formikProps.setFieldValue(criteriaScore.uuid, newScore)
      setShowNote(true)
    }

    const handleResolveDispute = async (criteriaScore, newStatus, newScore) => {
      setCriteriaLoading(true)
      const newCriteriaScore = {
        ...criteriaScore,
        dispute: {
          ...criteriaScore.dispute,
          manager_comment: '', // Nothing in the UI to populate this
          status: newStatus,
          score: newScore,
        },
        score: newScore,
      }
      await dispatch(resolveDispute(newCriteriaScore, userId))
      await formikProps.setFieldValue(criteriaScore.uuid, newScore)
      // This will immediately resolve the dispute without requiring a new page load
      setShowDisputeButtons(false)
      setCriteriaLoading(false)
      // Reapply the filter to clean up the page
      const currentFilter = selectedCriteriaFilter
      dispatch(
        setCallExplorerData({ selectedCriteriaFilter: CALL_EXPLORER_CRITERIA_FILTER_ENUMS.ALL })
      )
      dispatch(setCallExplorerData({ selectedCriteriaFilter: currentFilter }))
    }

    const renderCriteriaNoteSection = () => {
      return (
        <div className="criteria-note-container">
          <TextArea
            data-testid={`criteria-note-textarea-${criteriaScore.id}`}
            className="mb ui form"
            placeholder="Add a note about this criteria score..."
            value={formikProps.values[`${criteriaScore.uuid}-note`]}
            onChange={(e) =>
              formikProps.setFieldValue(`${criteriaScore.uuid}-note`, e.target.value || '')
            }
            onKeyDown={(e) => e.stopPropagation()}
            autoFocus
          />
        </div>
      )
    }

    return (
      <div id={`criteria-${index}`} data-testid={criteriaScore.id}>
        <div className={classNames('line', { focused: true })} />
        <div
          className={classNames('criteria-container flex-space-between medium-gap', { active })}
          key={criteriaScore.id}
        >
          <div className="criteria-container-content">
            <div className="flex-space-between flex-align-start small-gap">
              <Pill
                dataTestId={`criteria-type-pill-${criteriaScore.id}`}
                magical={criteriaScore.criteria_type === 'ai'}
                caution={criteriaScore.manual}
                success={criteriaScore.criteria_type === 'automated'}
                small
                style={{ marginBottom: '0.5rem' }}
              >
                {criteriaScore.criteria_type === 'ai'
                  ? 'Copilot'
                  : capitalize(criteriaScore.criteria_type)}
              </Pill>
              {!showDisputeButtons && (
                <Field
                  tabIndex={-1}
                  innerRef={ref}
                  index={index}
                  name={criteriaScore.uuid}
                  component={Dropdown}
                  className="icon secondary flex-space-between small-gap no-wrap width-128"
                  floating
                  button
                  placeholder=" -- "
                  icon="angle down"
                  options={[
                    { value: 1, text: 'Passed' },
                    { value: 0, text: 'Failed' },
                    { value: -1, text: 'N/A' },
                  ]}
                  onChange={(_, { value }) => handleScoreChange(value)}
                />
              )}
            </div>
            <h4 className="mb">{criteriaScore.name}</h4>
            {criteriaScore.description && (
              <p data-testid="criterion-description">{criteriaScore.description}</p>
            )}
            {!criteriaScore.dispute && showNote && renderCriteriaNoteSection()}
            {criteriaScore.criteria_type !== 'automated' && (
              <>
                {criteriaScore.ai_anchor_time && String(score.call_id) === String(callId) ? (
                  <Pill
                    className="clickable"
                    dataTestId={`jump-to-call-event-pill-${criteriaScore.id}`}
                    onClick={() => {
                      updateCallExplorerParams(location, history, {
                        criteriaScoreId: criteriaScore.id,
                        timestamp: criteriaScore.ai_anchor_time,
                      })
                      dispatch(
                        setHardSelectedEvent({
                          id: criteriaScore.id,
                          timestamp: criteriaScore.ai_anchor_time,
                          displayText: criteriaScore.name,
                          section: 'QACopilot',
                        })
                      )
                    }}
                    magical
                    small
                    icon={<IconSparkles />}
                  >
                    Jump to moment:
                    <div className="event-time">{formatTime(criteriaScore.ai_anchor_time)}</div>
                    <IconArrowUpRight />
                  </Pill>
                ) : (
                  <Pill small icon={<IconSparkles />} className="mb">
                    Not present in timestamp
                  </Pill>
                )}
              </>
            )}
            {criteriaScore.ai_explanation && (
              <Banner icon={<IconSparkles />} header="AI Explanation" magical>
                {criteriaScore.ai_explanation}
              </Banner>
            )}
            {criteriaScore.dispute && <Divider />}
            {disputeStatus === 'pending' && (
              <Pill dataTestId={`criteria-dispute-pill-${criteriaScore.id}`} critical small>
                Pending Dispute
              </Pill>
            )}
            {criteriaScore.dispute && (
              <>
                <Banner
                  emphasized
                  icon={<IconMessageReport />}
                  header={`Agent Dispute From: ${agentName}`}
                >
                  <div className="dispute-status">
                    Original score:{' '}
                    {getStatusForScore(
                      criteriaScore?.dispute?.previous_score ?? criteriaScore.score
                    )}
                  </div>
                  <div className="mb">{criteriaScore.dispute.user_comment}</div>
                  {showDisputeButtons && (
                    <div className="dispute-container">
                      <Button
                        disabled={criteriaLoading}
                        className="reject-button"
                        data-testid="reject-dispute-button"
                        onClick={async () => {
                          // Reject the agent's dispute and keep the original score
                          await handleResolveDispute(criteriaScore, 'declined', criteriaScore.score)
                        }}
                      >
                        Reject
                      </Button>

                      <SemanticDropdown
                        item
                        disabled={criteriaLoading}
                        text="Accept and change to..."
                        icon="angle down"
                        button
                        className="icon secondary svg-button accept-button"
                        floating
                        placeholder="Accept and change to..."
                        data-testid="accept-dispute-button"
                      >
                        <SemanticDropdown.Menu>
                          {/* For each item, make sure the score isn't what's already set, so that we filter out the "reject" option */}
                          {criteriaScore.score !== 1 && (
                            <SemanticDropdown.Item
                              onClick={async () => {
                                await handleResolveDispute(criteriaScore, 'accepted', 1)
                              }}
                            >
                              Passed
                            </SemanticDropdown.Item>
                          )}
                          {criteriaScore.score !== 0 && (
                            <SemanticDropdown.Item
                              onClick={async () => {
                                await handleResolveDispute(criteriaScore, 'accepted', 0)
                              }}
                            >
                              Failed
                            </SemanticDropdown.Item>
                          )}
                          {criteriaScore.score !== -1 && (
                            <SemanticDropdown.Item
                              onClick={async () => {
                                await handleResolveDispute(criteriaScore, 'accepted', -1)
                              }}
                            >
                              Skipped
                            </SemanticDropdown.Item>
                          )}
                        </SemanticDropdown.Menu>
                      </SemanticDropdown>
                    </div>
                  )}
                </Banner>
                {renderCriteriaNoteSection()}
              </>
            )}
          </div>
        </div>
      </div>
    )
  }
)
