import Papa from 'papaparse'

export const headersWhiteList = ['campaign_name', 'playbook_name', 'delete']

export const toTitleCase = (str) => {
  return str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const parseCSVData = (rawCSVData) => {
  const parsedCSVData = Papa.parse(rawCSVData, { transform: (value) => value.trim() })
  const [headers] = parsedCSVData.data
  const CSVRows = parsedCSVData.data.slice(1)
  const filteredHeaders = headers.filter((header) => headersWhiteList.includes(header))

  return CSVRows.map((row) => {
    return filteredHeaders.reduce((acc, header) => {
      const cellIndex = headers.indexOf(header)
      if (cellIndex >= 0) {
        acc[header] = row[cellIndex]
      }
      return acc
    }, {})
  })
}
