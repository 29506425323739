import React from 'react'
import classNames from 'classnames'
import { Popup, Button } from 'semantic-ui-react'

import { Pill } from '@/components/pills/Pill'
import { Drawer } from '@/components/drawer/Drawer'

import { CoachingDrawer } from './CoachingDrawer'

import './TaskCard.scss'

export const TaskCard = ({
  title,
  description,
  type = 'Copilot',
  date,
  className,
  primaryActionButtonText = 'Review',
  handlePrimaryAction,
  secondaryActionButtonText = 'Dismiss',
  handleSecondaryAction,
  ...props
}) => {
  return (
    <div className={classNames('task-card', className)} {...props}>
      <header>
        <h2>{title}</h2>
      </header>
      <section>
        <p>{description}</p>
      </section>
      <footer>
        <div className="task-card__metadata">
          <div>
            <Popup
              position="top center"
              inverted
              content={new Date().toLocaleString()}
              trigger={<small>Generated a few minutes ago</small>}
            />
          </div>
          <div className="task-card__metadata--divider" />
          <div>
            <Popup
              position="top center"
              inverted
              content={type === 'Copilot' ? 'Generated by Copilot' : 'Created by a manager'}
              trigger={
                <Pill
                  small
                  magical={type === 'Copilot'}
                  emphasized={type !== 'Copilot'}
                  bold
                  className="uppercase"
                >
                  {type}
                </Pill>
              }
            />
          </div>
        </div>
        <div className="flex-align-center small-gap">
          {handleSecondaryAction && (
            <Button type="button" basic onClick={handleSecondaryAction}>
              {secondaryActionButtonText}
            </Button>
          )}
          <Drawer
            wide
            title="Coaching Task"
            trigger={<Button secondary>{primaryActionButtonText}</Button>}
          >
            <CoachingDrawer />
          </Drawer>
        </div>
      </footer>
    </div>
  )
}
