import React, { useState } from 'react'
import { Button } from 'semantic-ui-react'
import { IconChevronRight } from '@tabler/icons-react'
import classNames from 'classnames'

import './InboxAccordion.scss'

export const InboxAccordion = ({ defaultExpanded = true, title, children, actions, muted }) => {
  const [expanded, setExpanded] = useState(defaultExpanded)

  const handleToggle = () => setExpanded(!expanded)

  return (
    <div className="inbox-accordion">
      <header>
        <div className="flex-align-center gap">
          <Button basic icon compact className="svg-button borderless" onClick={handleToggle}>
            <IconChevronRight
              className={classNames('icon-accordion', { 'icon-accordion--expanded': expanded })}
            />
            <h2 className={classNames({ 'muted-text': muted })}>{title}</h2>
          </Button>
        </div>
        <div className="flex-align-center small-gap">{actions}</div>
      </header>
      {expanded && <section>{children}</section>}
    </div>
  )
}
