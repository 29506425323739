import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { truncate, isEmpty } from 'lodash'
import { IconPencil, IconCheck, IconX } from '@tabler/icons-react'

import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import { AbstractForm } from '@/components/forms/formik/AbstractForm'
import { ButtonAndFormModal } from '@/components/layout/modals/ButtonAndFormModal'
import { closeModal } from '@/reducers/ui/ui.redux'
import { createOrganization } from '@/reducers/organizations/organizations.actions'
import { Pill } from '@/components/pills/Pill'

const OrganizationsPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const organizations = useSelector((state) => state.organizations)

  const redirectToEdit = (e, row) => {
    history.push(`organizations/${row.id}`)
  }

  const handleCreateOrganization = (formValues) => {
    dispatch(createOrganization({ name: formValues.name, push: history.push }))
    dispatch(closeModal())
  }

  const showEnabled = (enabled) =>
    enabled ? (
      <IconCheck className="icon-svg status-success" />
    ) : (
      <IconX className="icon-svg status-critical" />
    )

  const columns = [
    {
      accessor: 'name',
      label: 'Organization Name',
      clickableRowLink: { prefix: 'organizations', accessor: 'id' },
      format: (value) => truncate(value, { length: 40 }),
      sticky: true,
      collapsing: true,
      isSearchable: true,
    },
    {
      accessor: 'id',
      label: 'ID',
      collapsing: true,
      isSearchable: true,
    },
    {
      accessor: 'production',
      label: 'Production',
      collapsing: true,
      format: (value) =>
        value ? (
          <Pill small success>
            Production
          </Pill>
        ) : (
          <Pill small emphasized>
            Not Production
          </Pill>
        ),
    },
    {
      accessor: 'desktop_enterprise_enabled',
      label: 'Enterprise',
      format: (value) => showEnabled(value),
      collapsing: true,
    },
    {
      accessor: 'unredacted_enabled',
      label: 'Unredacted',
      format: (value) => showEnabled(value),
      collapsing: true,
    },
    {
      accessor: 'requires_compliance',
      label: 'Requires Compliance',
      format: (value) => showEnabled(value),
      collapsing: true,
    },
    {
      accessor: 'save_audio',
      label: 'Save Audio',
      format: (value) => showEnabled(value),
      collapsing: true,
    },
    {
      accessor: 'screen_capture_enabled',
      label: 'Screen Capture',
      format: (value) => showEnabled(value),
      collapsing: true,
    },
    {
      accessor: 'parent_id',
      label: 'Hierarchy Parent',
      collapsing: true,
    },
    {
      accessor: 'qa_enabled',
      label: 'Real Time QA',
      format: (value) => showEnabled(value),
      collapsing: true,
    },
    {
      accessor: 'qa_copilot_enabled',
      label: 'QA Copilot',
      format: (value) => showEnabled(value),
      collapsing: true,
    },
    {
      accessor: 'qa_disputes_enabled',
      label: 'Disputes',
      format: (value) => showEnabled(value),
      collapsing: true,
    },
    {
      accessor: 'realtime_coaching_access',
      label: 'Real-Time Coaching',
      format: (value) => showEnabled(value),
      collapsing: true,
    },
    {
      accessor: 'call_summarization_enabled',
      label: 'Call Summary',
      format: (value) => showEnabled(value),
      collapsing: true,
    },
  ]

  const actions = [{ label: 'Edit', fn: redirectToEdit, icon: <IconPencil /> }]

  return (
    <>
      <header className="data-grid-header page-header" data-testid="organizations-page">
        <h1>Organizations</h1>

        <ButtonAndFormModal
          buttonLabel="Create Organization"
          modalTitle="Create Organization"
          modalId="organizations/create"
          modalProps={{ size: 'tiny' }}
          buttonProps={{ primary: true }}
          form={
            <AbstractForm
              schema={[{ name: 'name', type: 'text', label: 'Name', required: true }]}
              onSubmit={handleCreateOrganization}
              buttonLabel="Create"
              onClose={() => dispatch(closeModal())}
            />
          }
        />
      </header>
      <AdvancedTable
        striped={false}
        loading={isEmpty(organizations)}
        dataGrid
        defaultOrderBy="name"
        columns={columns}
        rows={organizations}
        actions={actions}
        rowAction={redirectToEdit}
        rowsPerPage={30}
        stickyAction
        pagination
      />
    </>
  )
}

export default OrganizationsPage
