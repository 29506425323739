import React from 'react'
import classNames from 'classnames'

import { useTooltip } from '@/utils/hooks/useTooltip'
import { Tooltip } from '@/components/tooltips/Tooltip'

import './Matrix.scss'

export const Matrix = ({ data, tooltip, header, ...props }) => {
  const {
    tooltipRef,
    tooltipActive,
    tooltipContent,
    tooltipPosition,
    handleMouseEnter,
    handleMouseLeave,
    handleMouseMove,
  } = useTooltip()

  return (
    <div
      data-testid="matrix"
      className="matrix"
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {Boolean(tooltip) && (
        <Tooltip
          tooltipRef={tooltipRef}
          visible={Boolean(tooltipActive && tooltipContent)}
          position={tooltipPosition}
        >
          {tooltip(tooltipContent)}
        </Tooltip>
      )}

      {data.map((column) => (
        <div className="matrix-row" key={column.call_id}>
          {Boolean(header) && <header className="matrix-label">{header(column.label)}</header>}

          <div className="matrix-items">
            {column.items.map((row) => {
              return (
                <div
                  key={`${column.call_id}-${row.item}`}
                  className={classNames('matrix-item', { clickable: row.occurred > 0 })}
                  style={{ backgroundColor: row.color }}
                  data-tooltip-name={column.label.name}
                  data-tooltip-item={row.item}
                  data-tooltip-date={column.label.date}
                  data-tooltip-duration={column.label.duration}
                  onClick={() => {
                    if (props.onClick) {
                      props.onClick(column.call_id, row.item)
                    }
                  }}
                >
                  {row.occurred > 1 && row.occurred}
                </div>
              )
            })}
          </div>
        </div>
      ))}
    </div>
  )
}
