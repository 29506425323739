import React from 'react'

import { Button } from 'semantic-ui-react'
import { IconDotsVertical, IconSparkles, IconSettings } from '@tabler/icons-react'
import { InboxAccordion } from '@/components/inbox/components/InboxAccordion'
import { TaskCard } from '@/components/inbox/components/TaskCard'
import { FilterTypeSelect } from '@/components/filters/types/FilterTypeSelect'

export const CoachingInbox = () => {
  const [taskType, setTaskType] = React.useState('')
  const tasks = [
    {
      id: 123,
      title: 'Improve Customer Service Skills',
      description:
        'Work on enhancing communication and problem-solving skills to better assist customers.',
      type: 'Copilot',
    },
    {
      id: 456,
      title: 'Develop Leadership Qualities',
      description:
        'Participate in leadership training sessions to develop effective team management skills.',
      type: 'Manual',
    },
    {
      id: 789,
      title: 'Enhance Technical Knowledge',
      description:
        'Engage in technical workshops to stay updated with the latest industry trends and technologies.',
      type: 'Copilot',
    },
    {
      id: 101,
      title: 'Boost Sales Performance',
      description:
        'Implement new sales strategies to increase overall sales performance and achieve targets.',
      type: 'Manual',
    },
    {
      id: 112,
      title: 'Improve Time Management',
      description:
        'Learn and apply time management techniques to enhance productivity and meet deadlines.',
      type: 'Copilot',
    },
  ]

  const handlePrimaryAction = () => {
    console.info('Primary action clicked')
  }

  const handleSecondaryAction = () => {
    console.info('Secondary action clicked')
  }

  return (
    <div>
      <header className="page-header">
        <h1>Coaching Opportunities</h1>
        <Button secondary icon className="svg-button">
          <IconSettings />
          Settings
        </Button>
      </header>

      <FilterTypeSelect
        showAllOnEmpty
        schema={{
          accessor: 'organizationId',
          label: 'Tasks',
          options: [{ label: 'Task type 1', value: 'task1' }],
        }}
        value={taskType}
        updateSearch={(accessor, value) => {
          console.info(accessor, value)
          setTaskType(value)
        }}
      />

      <InboxAccordion
        title="To Do"
        actions={
          <>
            <Button basic icon compact className="svg-button">
              <IconDotsVertical />
            </Button>
            <Button secondary icon compact className="svg-button">
              <IconSparkles className="svg-icon status-magical" />
              Generate More
            </Button>
          </>
        }
      >
        {tasks.map((task) => (
          <TaskCard
            key={task.id}
            {...task}
            handlePrimaryAction={handlePrimaryAction}
            handleSecondaryAction={handleSecondaryAction}
          />
        ))}
      </InboxAccordion>
      <InboxAccordion title="Archive" muted defaultExpanded={false} />
    </div>
  )
}
