import React from 'react'
import { v4 } from 'uuid'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { Dropdown, Input, Button, Dimmer, Loader } from 'semantic-ui-react'

import {
  createNewVoipCampaign,
  fetchOrganizationVoipCampaigns,
  deleteOrganizationsVoipCampaign,
} from '@/reducers/organizations/organizationVoipCampaigns.actions'

import Form from '../Modal'
import ConfirmForm from './ConfirmForm'

class VoipCampaignForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      inputText: '',
      loading: false,
      selectedConfigCid: '',
      headers: ['VoIP Campaign Name', 'Playbook', 'Delete'],
    }

    this.toggleLoading = () => this.setState((prevState) => ({ loading: !prevState.loading }))

    this.updateInputText = (event, data) => this.setState(() => ({ inputText: data.value }))

    this.updateSelectedConfigCid = (event, data) =>
      this.setState(() => ({
        selectedConfigCid: data.value,
      }))

    this.fetchOrganizationVoipCampaigns = () => {
      const { organizationid } =
        this.props.organizationid === 1 ? this.props.match.params : this.props

      this.props.fetchOrganizationVoipCampaigns({
        organizationId: organizationid,
        toggleLoading: this.toggleLoading,
      })
    }

    this.createNewVoipCampaign = () => {
      const { inputText, selectedConfigCid } = this.state
      const { organizationid } =
        this.props.organizationid === 1 ? this.props.match.params : this.props

      this.toggleLoading()
      return this.props.createNewVoipCampaign({
        cid: selectedConfigCid,
        campaignName: inputText,
        organizationId: organizationid,
        toggleLoading: this.toggleLoading,
        reloadOrganizationsVoipCampaigns: this.fetchOrganizationVoipCampaigns,
        clearForm: () =>
          this.setState(() => ({
            inputText: '',
            selectedConfigCid: '',
          })),
      })
    }

    this.deleteOrganizationsVoipCampaign = (voipCampaignUuid) => {
      const { organizationid } =
        this.props.organizationid === 1 ? this.props.match.params : this.props

      this.toggleLoading()
      return this.props.deleteOrganizationsVoipCampaign({
        voipCampaignUuid,
        organizationId: organizationid,
        reloadOrganizationsVoipCampaigns: this.fetchOrganizationVoipCampaigns,
      })
    }
  }

  componentDidMount() {
    this.toggleLoading()
    this.fetchOrganizationVoipCampaigns()
  }

  render() {
    const { loading, inputText, selectedConfigCid, headers } = this.state
    const { voipCampaigns, configs } = this.props
    const { organizationid } =
      this.props.organizationid === 1 ? this.props.match.params : this.props

    const dropdownOptions = configs
      .filter(
        (config) =>
          Number(config.organization_id) === Number(organizationid) && config.config_active
      )
      .map((config) => ({
        key: config.cid,
        text: config.name,
        value: config.cid,
      }))

    const formattedVoipCampaigns = voipCampaigns.map((campaign) => {
      const [campaignConfig] = configs.filter((config) => config.cid === campaign.config_cid)
      // the order of this array here must match the headers
      return [
        campaign?.name || 'Missing Campaign Name',
        // if we don't find playbook because it's deleted show placeholder
        campaignConfig?.name || 'Deleted Playbook',
        <Form
          deleteButton
          header={`Delete ${campaign.name}`}
          icon="trash"
          form={
            <ConfirmForm
              valueToSubmit={campaign.uuid}
              bodyText="Are you sure you want to delete this VoIP campaign? This action cannot be undone."
              primaryButtonText="Delete"
              handleSubmit={() => this.deleteOrganizationsVoipCampaign(campaign.uuid)}
              danger
            />
          }
        />,
      ]
    })

    return (
      <Dimmer.Dimmable>
        <div style={{ height: 500, overflowX: 'auto' }} data-testid="manage-voip-campaigns-form">
          <table className="ui celled table">
            <thead>
              <tr>
                {headers.map((header) => (
                  <th key={v4()}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {formattedVoipCampaigns.map((row) => (
                <tr key={v4()}>
                  {row.map((cell) => (
                    <td key={v4()}>{cell}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Input
          fluid
          action
          type="text"
          value={inputText}
          maxLength={100}
          onChange={this.updateInputText}
          placeholder="VoIP Campaign Name..."
        >
          {/* Multiple Actions require children. When using children,
          you must add a placeholder <input />. */}
          <input />
          <Dropdown
            search
            selection
            options={dropdownOptions}
            value={selectedConfigCid}
            placeholder="Playbook name"
            onChange={this.updateSelectedConfigCid}
          />
          <Button
            primary
            type="submit"
            onClick={this.createNewVoipCampaign}
            // if either value is empty we disable save button
            disabled={!inputText || !selectedConfigCid}
          >
            Save
          </Button>
        </Input>
        {loading && (
          <Dimmer active inverted>
            <Loader active />
          </Dimmer>
        )}
      </Dimmer.Dimmable>
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      dispatch,
      createNewVoipCampaign,
      fetchOrganizationVoipCampaigns,
      deleteOrganizationsVoipCampaign,
    },
    dispatch
  )

export default withRouter(
  connect(
    (state) => ({
      configs: state.configs,
      organizationid: state.currentUser.organizationid,
      voipCampaigns: state.organizationVoipCampaigns.voipCampaigns,
    }),
    mapDispatchToProps
  )(VoipCampaignForm)
)
