import React, { useState } from 'react'
import { Button, Popup } from 'semantic-ui-react'
import { IconDeviceFloppy } from '@tabler/icons-react'
import { isFunction, isEmpty } from 'lodash'

import { customStaticRanges } from '@/components/helpers'
import { AbstractForm } from '@/components/forms/formik/AbstractForm'
import * as Yup from 'yup'

export const SaveCallFilter = ({ handleSave, filterDateRangeLabel, filterValuesApplied }) => {
  const [open, setOpen] = useState(false)

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Filter name is required'),
  })

  const dateRangeOptions = customStaticRanges.map((staticRange) => {
    return {
      value: staticRange.label.toLowerCase(),
      label: staticRange.label,
    }
  })

  const defaultDateRangeSelected =
    dateRangeOptions.find((option) => option.label === filterDateRangeLabel)?.value || 'today'

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Popup
      open={open}
      basic
      on="click"
      onClose={handleClose}
      onOpen={handleOpen}
      className="popup-form"
      position="bottom right"
      content={
        <div>
          <h2>Save New Filter</h2>
          <p>Create a new saved filter from the currently applied call explorer filters.</p>

          {!isEmpty(filterValuesApplied) ? (
            <div className="mb">
              {filterValuesApplied.map(([accessor, properties]) => (
                <div key={`${accessor}-display`}>
                  <strong>{properties.label}: </strong>
                  <span>
                    {isFunction(properties.format) ? properties.format(accessor) : properties.value}
                  </span>
                </div>
              ))}
            </div>
          ) : (
            <p>
              <em>No additional filters have been applied.</em>
            </p>
          )}

          <AbstractForm
            validationSchema={validationSchema}
            schema={[
              {
                name: 'name',
                type: 'text',
                label: 'Filter Name',
                autoFocus: true,
                required: true,
              },
              {
                name: 'isDefault',
                type: 'checkbox',
                label: 'Set as default filter',
              },
              {
                label: 'Date Range',
                name: 'dateRange',
                type: 'select',
                options: dateRangeOptions,
                defaultValue: defaultDateRangeSelected,
                isClearable: false,
              },
            ]}
            onClose={handleClose}
            closeButtonLabel="Cancel"
            buttonLabel="Save New Filter"
            onSubmit={(values, { setSubmitting }) => {
              handleSave(values)
              setSubmitting(false)
              handleClose()
            }}
          />
        </div>
      }
      trigger={
        <Button
          type="button"
          compact
          icon
          secondary
          className="svg-button"
          data-testid="call-search-save-filter-button"
        >
          <IconDeviceFloppy />
          Save Filter As...
        </Button>
      }
    />
  )
}
