import React from 'react'

import './Tooltip.scss'

export const Tooltip = ({ tooltipRef, position, visible, children }) => {
  if (visible) {
    return (
      <div
        data-testid="tooltip"
        className="tooltip"
        ref={tooltipRef}
        style={{ top: position.y, left: position.x }}
      >
        {children}
      </div>
    )
  }

  return null
}
