import React from 'react'
import { withRouter } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import { IconCheck, IconAlertTriangle } from '@tabler/icons-react'
import { useDispatch, useSelector } from 'react-redux'
import { updateUploadResponse } from '@/reducers/organizations/organizationVoipCampaignsCSV.redux'
import { uploadVoipCampaignCSV } from '@/reducers/organizations/organizationVoipCampaigns.actions'

const Preview = ({ nextPanel, match }) => {
  const dispatch = useDispatch()

  const { organizationid } = match.params

  const { parsedCSV, uploadResponse } = useSelector((state) => state.organizationVoipCampaignsCSV)

  const processCSVUpdates = async () => {
    try {
      // Wait for the uploadVoipCampaignCSV to complete
      const uploadResponse = await dispatch(uploadVoipCampaignCSV(organizationid, parsedCSV, false))
      dispatch(updateUploadResponse(uploadResponse))

      nextPanel()
    } catch (error) {
      // fetchingAPI shows "Action Failed" alert on error
      console.error('Error processing bulk VOIP campaign upload:', error)
    }
  }

  const newMappings = uploadResponse.additions || {}
  const updateMappings = uploadResponse.updates || {}
  const deleteMappings = uploadResponse.deletions || []
  const invalidPlaybookNames = uploadResponse.invalid_playbooks || {}
  const noOps = uploadResponse.no_ops || {}
  const conflicts = uploadResponse.conflicting_rows || []

  const validationErrors = conflicts.length > 0 || Object.keys(invalidPlaybookNames).length > 0
  const noChangesToMake =
    Object.keys(newMappings).length === 0 &&
    Object.keys(updateMappings).length === 0 &&
    deleteMappings.length === 0

  return (
    <>
      <div>
        <span className="flex-align-center small-gap">
          <label style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Upload Preview</label>
        </span>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: 10,
          }}
        >
          {Object.keys(invalidPlaybookNames).length > 0 && (
            <span className="flex-align-center small-gap">
              <IconAlertTriangle className="icon-svg status-critical" />
              <label style={{ fontWeight: 'bold' }}>Invalid playbook names: </label>
              <p>
                {Object.entries(invalidPlaybookNames)
                  .map(([campaign, playbook]) => `${playbook} (${campaign})`)
                  .join(', ')}
              </p>
            </span>
          )}
          {conflicts.length > 0 && (
            <span className="flex-align-center small-gap">
              <IconAlertTriangle className="icon-svg status-critical" />
              <label style={{ fontWeight: 'bold' }}>Repeated campaign names with conflicts: </label>
              <p>{conflicts.join(', ')}</p>
            </span>
          )}
          {Object.keys(newMappings).length > 0 && (
            <span className="flex-align-center small-gap">
              <IconCheck className="icon-svg status-success" />
              <label style={{ fontWeight: 'bold' }}>New mappings to create: </label>
              <p>{Object.keys(newMappings).length}</p>
            </span>
          )}
          {Object.keys(updateMappings).length > 0 && (
            <span className="flex-align-center small-gap">
              <IconCheck className="icon-svg status-success" />
              <label style={{ fontWeight: 'bold' }}>Existing mappings to update: </label>
              <p>{Object.keys(updateMappings).length}</p>
            </span>
          )}
          {deleteMappings.length > 0 && (
            <span className="flex-align-center small-gap">
              <IconCheck className="icon-svg status-success" />
              <label style={{ fontWeight: 'bold' }}>Mappings to delete: </label>
              <p>{deleteMappings.length}</p>
            </span>
          )}
          {Object.keys(noOps).length > 0 && (
            <span className="flex-align-center small-gap">
              <IconCheck className="icon-svg status-success" />
              <label style={{ fontWeight: 'bold' }}>Campaigns in csv requiring no-op: </label>
              <p>{Object.keys(noOps).length}</p>
            </span>
          )}
        </div>

        <Button
          primary
          fluid
          onClick={processCSVUpdates}
          disabled={validationErrors || noChangesToMake}
        >
          Process Updates
        </Button>
      </div>
    </>
  )
}

export default withRouter(Preview)
