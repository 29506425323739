import { toast } from 'react-toastify'

import { setOrganizationVoipCampaigns } from './organizationVoipCampaigns.redux'
import { fetchingAPI, apiService } from '../../api'

export const fetchOrganizationVoipCampaigns =
  ({ organizationId, toggleLoading }) =>
  async (dispatch) => {
    try {
      const voipCampaigns = await fetchingAPI(
        `${apiService.web}/api/organizations/${organizationId}/voip_campaigns`,
        'GET',
        dispatch
      )
      if (toggleLoading) toggleLoading()

      dispatch(setOrganizationVoipCampaigns(voipCampaigns))
    } catch (err) {
      if (toggleLoading) toggleLoading()
    }
  }

// TODO: Refactor toggleLoading
export const createNewVoipCampaign =
  ({
    cid,
    clearForm,
    campaignName,
    toggleLoading,
    organizationId,
    reloadOrganizationsVoipCampaigns,
  }) =>
  async (dispatch) => {
    try {
      await fetchingAPI(
        `${apiService.web}/api/organizations/${organizationId}/voip_campaigns`,
        'POST',
        dispatch,
        JSON.stringify({
          cid,
          campaignName,
        })
      )
      reloadOrganizationsVoipCampaigns()
      clearForm()
    } catch (err) {
      toast.error(`Failed to create VoIP campaign. ${err?.error_message}`)
      if (toggleLoading) toggleLoading()
    }
  }

export const deleteOrganizationsVoipCampaign =
  ({ organizationId, voipCampaignUuid, reloadOrganizationsVoipCampaigns }) =>
  async (dispatch) => {
    try {
      await fetchingAPI(
        `${apiService.web}/api/organizations/${organizationId}/voip_campaigns/${voipCampaignUuid}`,
        'DELETE',
        dispatch
      )
      reloadOrganizationsVoipCampaigns()
    } catch (err) {
      toast.error('Failed to delete VoIP campaign')
    }
  }

export const uploadVoipCampaignCSV = (organizationid, parsedRows, dryRun) => async (dispatch) => {
  const csvData = await fetchingAPI(
    `${apiService.web}/api/organizations/${organizationid}/voip_campaigns/upload?dry=${Boolean(dryRun)}`,
    'POST',
    dispatch,
    JSON.stringify(parsedRows)
  )
  return csvData
}
