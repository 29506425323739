import React, { useEffect } from 'react'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'
import { Header } from 'semantic-ui-react'

import { updateSummaryConfigForPlaybook } from '@/reducers/playbooks/playbook.actions'
import { setRTNPrompt } from '@/reducers/playbooks/playbook.redux'
import { PromptForm } from './PromptForm'
import { VanityMetrics } from './components/VanityMetrics'
import './RealtimeNotetakerPage.scss'

const RealtimeNotetakerPage = () => {
  const dispatch = useDispatch()
  const { rtnPrompt, rtnOrg } = useSelector((state) => state.playbook)

  useEffect(() => {
    dispatch(setRTNPrompt(''))
  }, [])

  const handleUpdateRTNPrompt = (values) => {
    const newPrompt = {
      ...rtnPrompt,
      user_prompt: values.user_prompt || '',
      organization_id: rtnOrg,
      model: values.model,
    }

    dispatch(updateSummaryConfigForPlaybook(values.selected_playbook_cid, newPrompt))
  }

  const promptInitialValues = {
    user_prompt: rtnPrompt?.user_prompt || '',
    model: rtnPrompt?.model || 'gpt-4o-mini',
    prompt_id: rtnPrompt?.id || null,
    call_id: '',
    prompt_fields: [{ key: '', value: '' }],
    selected_playbook_cid: '',
  }

  const validationSchema = Yup.object().shape({
    call_id: Yup.number().typeError('Call ID must be a number'),
    prompt_fields: Yup.array().of(
      Yup.object().shape({
        key: Yup.string().required('Key is required'),
        value: Yup.string()
          .max(1100, 'Value cannot be more than 1,100 characters')
          .required('Value is required'),
      })
    ),
  })

  return (
    <>
      <Header data-testid="realtime-notetaker-page" className="page-header">
        Notetaker
      </Header>
      <VanityMetrics />
      <Formik
        initialValues={promptInitialValues}
        onSubmit={handleUpdateRTNPrompt}
        validateOnMount
        validationSchema={validationSchema}
        enablereinitialize
      >
        {({ handleSubmit, values, setFieldValue }) => {
          return (
            <PromptForm handleSubmit={handleSubmit} values={values} setFieldValue={setFieldValue} />
          )
        }}
      </Formik>
    </>
  )
}

export default RealtimeNotetakerPage
