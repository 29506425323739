import React from 'react'
import { Image, List } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { uploadVoipCampaignCSV } from '@/reducers/organizations/organizationVoipCampaigns.actions'
import {
  setParsedCsv,
  updateUploadResponse,
} from '@/reducers/organizations/organizationVoipCampaignsCSV.redux'
import exampleVoipCSV from '../../../assets/images/exampleVoipCSV.png'
import { headersWhiteList, toTitleCase, parseCSVData } from './helpers'
import CSVReader from '../../CSVReader'

const Instructions = ({ nextPanel, match }) => {
  const dispatch = useDispatch()
  const { organizationid } = match.params

  const downloadCSVTemplate = () => {
    const templateData = [
      headersWhiteList,
      ['fake campaign', 'my playbook', ''],
      ['campaign to delete', '', 'true'],
    ]
    const csvData = templateData.map((row) => row.map((cell) => `"${cell}"`).join(',')).join('\n')
    const csvBlob = new Blob([csvData], { type: 'text/csv;charset=utf-8' })
    const encodedUri = URL.createObjectURL(csvBlob)
    const link = document.createElement('a')

    link.setAttribute('href', encodedUri)
    link.setAttribute('download', 'balto_bulk_voip_campaign_upload_template.csv')

    document.body.appendChild(link) // Required for FF
    link.click()
    document.body.removeChild(link)
  }
  const handleCSVUpload = async (csvData) => {
    const parsedData = parseCSVData(csvData)
    dispatch(setParsedCsv(parsedData))

    try {
      const dryRunResponse = await dispatch(uploadVoipCampaignCSV(organizationid, parsedData, true))
      dispatch(updateUploadResponse(dryRunResponse))
      nextPanel()
    } catch (error) {
      // fetchingAPI shows "Action Failed" alert on error
      console.error('Error uploading CSV:', error)
    }
  }

  const requiredStrings = ['campaign_name']
  const optionalStrings = ['playbook_name', 'delete']

  const titlesAndDescriptions = {
    campaign_names: () => 'Campaign names are case insensitive',
    playbooks: () =>
      'Playbook names <strong>must match exactly</strong> (case sensitive, whitespace, etc.)',
    delete_flag: () =>
      'If set to <strong>TRUE</strong> or <strong>1</strong>, will delete the mapping for the campaign, regardless of the playbook value of the row',
  }

  const renderDescription = (description) => {
    const parts = description.split(/(<strong>|<\/strong>)/)

    return parts.map((part, index) => {
      if (part === '<strong>' || part === '</strong>') {
        return null
      }
      if (index > 0 && parts[index - 1] === '<strong>') {
        return <strong key={parts[index]}>{part}</strong>
      }

      return part
    })
  }

  return (
    <div>
      <Image
        fluid
        onClick={() => downloadCSVTemplate()}
        style={{ cursor: 'pointer' }}
        label={{
          as: 'a',
          color: 'blue',
          content: 'Click to Download Template',
          onClick: (e) => {
            e.stopPropagation()
            downloadCSVTemplate()
          },
          ribbon: true,
        }}
        src={exampleVoipCSV}
      />

      <List divided relaxed>
        <div className="ui grid">
          <div className="five wide column">
            <List.Item>
              <List.Content>
                <List.Header>
                  <strong>Headers</strong>
                </List.Header>
                <List.Description>
                  We <strong>only</strong> accept these headers
                  <ul>
                    {requiredStrings.map((required) => (
                      <li key={required}>
                        <code>{required}</code>
                      </li>
                    ))}
                    {optionalStrings.map((option) => (
                      <li key={option}>
                        <code>{option}</code> (optional)
                      </li>
                    ))}
                  </ul>
                </List.Description>
              </List.Content>
            </List.Item>
          </div>
          <div className="eleven wide column">
            {Object.entries(titlesAndDescriptions).map(([key, getDescription]) => (
              <List.Item key={key}>
                <List.Content>
                  <List.Header>
                    <strong key={`${key}-strong`}>{toTitleCase(key)}</strong>
                  </List.Header>
                  <List.Description key={`${key}-description`}>
                    {renderDescription(getDescription())}
                  </List.Description>
                </List.Content>
              </List.Item>
            ))}
          </div>
        </div>
      </List>
      <CSVReader onFileLoaded={(csvData) => handleCSVUpload(csvData)} />
    </div>
  )
}

export default withRouter(Instructions)
